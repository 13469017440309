import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { FuelContext } from '~/providers/FuelProvider';
import { PAGE_ROUTES } from '~/constants/pages';
import { ReduxState } from '~/types/redux';
import { LoaderMetadata, LoaderPageMetadata } from './components/EditableLoader';
import { MonarchPreampPlacement, useMonarchPreampPlacement } from '@redventures/cohesion-utils-react';
import { getBaseAssetName } from '~/helpers/getBaseAssetName';

const Default = dynamic(() => import('./components/Default'));
const AddressCheck = dynamic(() => import('./components/AddressCheck'));
const CreditCheck = dynamic(() => import('./components/CreditCheck'));
const Checkout = dynamic(() => import('./components/Checkout'));
const EditableLoader = dynamic(() => import('./components/EditableLoader'));

const Loader = (): JSX.Element => {
  const metadata = useMonarchPreampPlacement<LoaderMetadata>('1FZh5t6bx0Tv3QrZiwVBCS')?.data || {};

  const { router } = useContext(FuelContext);
  const { pathname: currentPage } = router;
  const { pageLoading, unservRedirect } = useSelector((state: ReduxState) => state);

  const addressCheck = currentPage === PAGE_ROUTES.ADDRESS_CHECK;
  const addressCheckAi = currentPage === PAGE_ROUTES.CUSTOMIZE;
  const creditCheckPage = currentPage === PAGE_ROUTES.CONTACT_CREDIT;
  const productSelectionStep = currentPage === PAGE_ROUTES.PLAN_PACKAGE;
  const fiber = currentPage === PAGE_ROUTES.BUY_FIBER;
  const resume = currentPage === PAGE_ROUTES.RESUME;
  const myCustomQuote = currentPage === PAGE_ROUTES.MY_CUSTOM_QUOTE;
  const checkout = currentPage === PAGE_ROUTES.CHECKOUT;

  const loadingText = (() => {
    if (productSelectionStep) return 'Just a moment, we’re getting your plan ready for activation.';
    if (creditCheckPage) return 'This step may take up to 2 minutes.';
    if (fiber) return 'Searching for the best fiber upgrades.';
    if (resume) return 'Loading your previous selections.';
    return 'Just a moment...';
  })();
  const loadingTextHeader = creditCheckPage ? 'Verifying your identity' : '';

  // Move this check outside of the Preamp placement so we don't get
  // a flash of the confirmation page before redirecting to Frontier's page
  if (currentPage.includes('confirmation')) {
    return <Default currentPage={currentPage} loadingText={loadingText} loadingTextHeader={loadingTextHeader} />;
  }

  const gdtPageConfig = metadata?.[PAGE_ROUTES.MY_CUSTOM_QUOTE] as LoaderPageMetadata;
  const currentPageConfig = metadata?.[currentPage] as LoaderPageMetadata;

  const customQuoteRedirectDisabled =
    myCustomQuote &&
    (!gdtPageConfig?.unservRedirectEnabled ||
      gdtPageConfig?.unservRedirectEnabled === 'false' ||
      !unservRedirect);

  const disableLoader =
      !pageLoading ||
      customQuoteRedirectDisabled ||
      (myCustomQuote && gdtPageConfig?.unservRedirectEnabled === 'false') ||
      addressCheckAi;

  if (disableLoader) return null;

  if (
    currentPageConfig?.enabled === 'true' ||
    (currentPageConfig?.unservRedirectEnabled === 'true' && unservRedirect)
  ) {
    return <EditableLoader metadata={metadata} currentPage={currentPage} />;
  }

  if (addressCheck || fiber) {
    return <AddressCheck />;
  }

  if (creditCheckPage) {
    return <CreditCheck />;
  }

  if (checkout) {
    return <Checkout />;
  }

  return <MonarchPreampPlacement placement='1FZh5t6bx0Tv3QrZiwVBCS' componentId={getBaseAssetName}> <Default currentPage={currentPage} loadingText={loadingText} loadingTextHeader={loadingTextHeader} /> </MonarchPreampPlacement>;
};

Loader.displayName = 'Loader';

export default Loader;
