/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Star2 = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.75409 0.342057C3.80416 0.0688003 4.19584 0.0687995 4.24591 0.342056L4.74318 3.05597C4.7619 3.15815 4.84185 3.2381 4.94403 3.25682L7.65794 3.75409C7.9312 3.80416 7.9312 4.19584 7.65794 4.24591L4.94403 4.74318C4.84185 4.7619 4.7619 4.84185 4.74318 4.94403L4.24591 7.65794C4.19584 7.9312 3.80416 7.9312 3.75409 7.65794L3.25682 4.94403C3.2381 4.84185 3.15815 4.7619 3.05597 4.74318L0.342057 4.24591C0.0688003 4.19584 0.0687995 3.80416 0.342056 3.75409L3.05597 3.25682C3.15815 3.2381 3.2381 3.15815 3.25682 3.05597L3.75409 0.342057Z"
        fill={props.fill || 'currentColor'}
      />
    </svg>
  );
};

Star2.displayName = 'Star2';

export default Star2;
