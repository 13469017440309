import { BaseResponse } from './common';
import { LegalMap } from './legal';
import { OfferObject } from './offerObject';
import { Product } from './products';

export type AnswerChoice = {
  id: number;
  answerLabel: string;
  value: string;
};

export type QuestionBase = {
  id: string;
  questionText: string;
  subTitle?: string;
  questionType: 'SELECT';
  optionalCheckbox?: string;
};

export type SelectQuestion = QuestionBase & {
  answerChoices: AnswerChoice[];
  allowMultiple?: boolean;
};

export type DetailedQuizAnswer = {
  questionText: string;
  questionAnswerId: string | string[];
  questionAnswer: string | string[];
};

export type CombinedQuizAnswer = DetailedQuizAnswer & {
  checkBoxQuestion?: {
    question: string;
    answer: boolean;
  };
};

export type QuizQuestion = SelectQuestion;

export interface DiscoveryToolQuestionsResponse extends BaseResponse {
  questions: QuizQuestion[];
}

export interface ProductsResponse extends BaseResponse {
  productList: OfferObject[];
  availableProducts: Product[];
  legal: LegalMap;
  broadbandType: string;
}

export enum ActionTypes {
  SET_DISCOVERY_QUESTIONS = 'discoveryQuestions/set',
}

export interface SetDiscoveryQuestionsAction {
  type: ActionTypes.SET_DISCOVERY_QUESTIONS;
  discoveryQuestions: QuizQuestion[];
}

export type Actions = SetDiscoveryQuestionsAction;
