/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Notification = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--notification"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4793 18C20.0838 18 21.4511 14.9091 19.6991 12.982L19.4308 12.6868C18.5104 11.6744 18.0004 10.3553 18.0004 8.98706V6C18.0004 3.23858 15.7619 1 13.0004 1L11.0004 1C8.239 1 6.00043 3.23858 6.00043 6L6.00043 8.98706C6.00043 10.3553 5.49045 11.6744 4.5701 12.6868L4.30171 12.982C2.54978 14.9091 3.9171 18 6.52153 18H17.4793ZM18.2193 14.3273C18.8032 14.9697 18.3475 16 17.4793 16H6.52153C5.65339 16 5.19761 14.9697 5.78159 14.3273L6.04998 14.0321C7.30501 12.6516 8.00043 10.8528 8.00043 8.98706L8.00043 6C8.00043 4.34315 9.34357 3 11.0004 3H13.0004C14.6573 3 16.0004 4.34315 16.0004 6V8.98706C16.0004 10.8528 16.6958 12.6516 17.9509 14.0321L18.2193 14.3273Z"
        fill="#141928"
      />
      <path
        d="M11.5004 19C10.672 19 10.0004 19.6716 10.0004 20.5V21.5C10.0004 22.3284 10.672 23 11.5004 23H12.5004C13.3289 23 14.0004 22.3284 14.0004 21.5V20.5C14.0004 19.6716 13.3289 19 12.5004 19H11.5004Z"
        fill="#141928"
      />
    </svg>
  );
};

Notification.displayName = 'Notification';

export default Notification;
