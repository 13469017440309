/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Money = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="icon icon--money"
    >
      <g>
        <path
          d="M15.0822 9.25049H18.6658V11.2505H10.416C10.2319 11.2505 10.0827 11.3997 10.0827 11.5838V12.6671C10.0827 12.8512 10.2319 13.0004 10.416 13.0004H16.9158C18.2044 13.0004 19.2491 14.0451 19.2491 15.3337V16.417C19.2491 17.7056 18.2044 18.7503 16.9158 18.7503H15.0822V20.6671H13.0822V18.7503H8.66602V16.7503H16.9158C17.0999 16.7503 17.2491 16.6011 17.2491 16.417V15.3337C17.2491 15.1496 17.0999 15.0004 16.9158 15.0004H10.416C9.12734 15.0004 8.08267 13.9557 8.08267 12.6671V11.5838C8.08267 10.2952 9.12734 9.25049 10.416 9.25049H13.0822V7.33398H15.0822V9.25049Z"
          fill="#141928"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666016 11.3337C0.666016 5.44262 5.44165 0.666992 11.3327 0.666992H16.666C22.5571 0.666992 27.3327 5.44262 27.3327 11.3337V16.667C27.3327 22.558 22.5571 27.3337 16.666 27.3337H11.3327C5.44164 27.3337 0.666016 22.558 0.666016 16.667V11.3337ZM11.3327 3.33366H16.666C21.0843 3.33366 24.666 6.91538 24.666 11.3337V16.667C24.666 21.0853 21.0843 24.667 16.666 24.667H11.3327C6.9144 24.667 3.33268 21.0853 3.33268 16.667V11.3337C3.33268 6.91538 6.9144 3.33366 11.3327 3.33366Z"
          fill="#141928"
        />
      </g>
    </svg>
  );
};

Money.displayName = 'Money';

export default Money;
