import { Actions, ActionTypes } from '~/types/quiz';
import { ReduxState } from '~/types/redux';

const quiz = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_QUIZ:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          [action.key]: action.value,
        },
      };
    case ActionTypes.RESET_QUIZ:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          quizStarted: false,
          currentQuestionIndex: 0,
          quizTotal: 0,
          answers: {},
          nextGenCartResults: {},
        },
      };
    default:
      return state;
  }
};

export default quiz;
