import { FC, Fragment } from 'react';
import { commonConfigs, environmentMap } from '~/constants/config';
import { DOMAINS } from '~/constants/config/common';

const { frdc, infr, frin } = commonConfigs;
const { frdc: prodFRDC, infr: prodINFR, frin: prodFRIN } = environmentMap.production;
const { frdc: devFRDC, infr: devINFR, frin: devFRIN } = environmentMap.development;

const Frontier: FC = () => (
  <Fragment>
    <link rel="preload" as="script" href={process.env.lpCohesionUrl} />
    <script type="text/javascript" dangerouslySetInnerHTML={{
      __html: `
      (function () {
      var production = ${process.env.environment === 'production'};
      var settings = {
        frdc: {
          tenantId: '${frdc.tenantId}',
          apiKey: '${frdc.apiKey}',
          fuseLease: '${frdc.fuseLease}',
          siteId: '${frdc.siteId}',
          writeKey: production ? '${prodFRDC.writeKey}' : '${devFRDC.writeKey}',
          sourceKey: production ? '${prodFRDC.sourceKey}' : '${devFRDC.sourceKey}',
          token: '${frdc.token}',
          sourceId: '${frdc.sourceId}'
        },
        infr: {
          tenantId: '${infr.tenantId}',
          apiKey: '${infr.apiKey}',
          fuseLease: '${infr.fuseLease}',
          siteId: '${infr.siteId}',
          writeKey: production ? '${prodINFR.writeKey}' : '${devINFR.writeKey}',
          sourceKey: production ? '${prodINFR.sourceKey}' : '${devINFR.sourceKey}',
          token: '${infr.token}',
          sourceId: '${infr.sourceId}',
          cookieDomain: production ? 'frontier.com' : ''
        },
        frin: {
          tenantId: '${frin.tenantId}',
          apiKey: '${frin.apiKey}',
          fuseLease: '${frin.fuseLease}',
          siteId: '${frin.siteId}',
          writeKey: production ? '${prodFRIN.writeKey}' : '${devFRIN.writeKey}',
          sourceKey: production ? '${prodFRIN.sourceKey}' : '${devFRIN.sourceKey}',
          token: '${frin.token}',
          sourceId: '${frin.sourceId}'
        }
      };

      var siteKey = '';
      var hostnameParts = window.location?.hostname?.split('.') ?? [];
      if (/pr\-/.test(hostnameParts?.[0])) { hostnameParts.shift(); }
      var hostname = hostnameParts.join('.');
      if ('${DOMAINS.frdc}'.includes(hostname)) {
        siteKey = 'frdc';
      } else if ('${DOMAINS.infr}'.includes(hostname)) {
        siteKey = 'infr';
      } else if ('${DOMAINS.frin}'.includes(hostname)) {
        siteKey = 'frin';
      }
      var siteSettings = settings[siteKey] || {};
      var monarchDomain = siteKey === 'frin' ? '${process.env.frinMonarchDomain}' : '${process.env.lpMonarchDomain}';
      var cohesionUrl = siteKey === 'frin' ? '${process.env.frinCohesionUrl}' : '${process.env.lpCohesionUrl}';

      var cohesionConfig = {
        tagular: {
          apiKey: siteSettings.apiKey || '',
          writeKey: siteSettings.writeKey || '',
          sourceKey: siteSettings.sourceKey || '',
          apiVersion: 'v2/t',
          cookieDomain: siteSettings.cookieDomain || window.document.domain,
          pollTDID: true,
        },
        monarch: {
          token: siteSettings.token || '',
          sourceId: siteSettings.sourceId || '',
          endpoint: monarchDomain,
          cookies: ["highestAvailableSpeed", "fiberEligibleAddress", "resumeEnabled", "queryString", "expName", "expNumber", "moversSwitchers", "addressOfferType", "zip", "selectedProductId", "dma", "dmaName", "productSelected", "phoneSelected", "1gig", "voiceEligible", "unservRedirectProvider", "netflixProduct"],
          autoRequest: {
            enabled: true,
            suppressPlacements: false,
          },
        },
        consent: {
          required: false,
        }
      }

      if (!window.isBot()) {
        if (siteSettings?.fuseLease?.length === 22) {
          cohesionConfig.fuse = {
            leases: [{ pool: siteSettings?.fuseLease }],
            metadata: { vdn: '68231' }
          }
        }
      }

      !(function (co, h, e, s, i, o, n) {
        var d = "documentElement";
        var a = "className";
        h[d][a] += " preampjs fusejs";
        n.k = e;
        co._Cohesion = n;
        co._Preamp = { k: s, start: new Date() };
        co._Fuse = { k: i };
        co._Tagular = { k: o };
        [e, s, i, o].map(function (x) {
          co[x] =
            co[x] ||
            function () {
              (co[x].q = co[x].q || []).push([].slice.call(arguments));
            };
        });
        var b = function () {
          var u = h[d][a];
          h[d][a] = u.replace(/ ?preampjs| ?fusejs/g, "");
        };
        h.addEventListener('DOMContentLoaded', function () {
          co.setTimeout(b, 3e3);
          co._Preamp.docReady = co._Fuse.docReady = !0;
        });
        var z = h.createElement('script');
        z.async = 1;
        z.src = cohesionUrl;
        z.onerror = function () {
          var ce = 'error',
            f = 'function';
          for (var o of co[e].q || []) o[0] === ce && typeof o[1] == f && o[1]();
          co[e] = function (n, cb) {
            n === ce && typeof cb == f && cb();
          };
          b();
        };
        h.head.appendChild(z);
      })(window, document, "cohesion", "preamp", "fuse", "tagular", cohesionConfig);
    })();

      `
    }} />
  </Fragment>
);

Frontier.displayName = 'Cohesion';

export default Frontier;
