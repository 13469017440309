import styled, { css } from 'styled-components';
import convertHexToRgba from '~/helpers/convertHexToRgba';

const ModalStyled = styled.div.attrs({
  className: 'modal',
})`
  ${({ theme, visible, zIndex }) => {
    const { colors } = theme;

    return css`
      ${visible ? 'display: flex' : 'display: none'};
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${convertHexToRgba(colors.primary.black, 0.5)};
      z-index: ${zIndex || 20};
      overflow-y: auto;
    `;
  }};
`;

export default ModalStyled;
