import React, { FC } from 'react';
import Frontier from './Frontier';
import Allconnect from './Allconnect';

const Cohesion: FC = () => {
  if (process.env.allconnect) return <Allconnect />;
  return <Frontier />;
};

Cohesion.displayName = 'Cohesion';

export default Cohesion;
