import Cookies from 'js-cookie';

export const getCookie = (name: string): string | null => {
  try {
    const value = Cookies.get(name);
    return value || null;
  } catch (error) {
    console.error(`Error getting cookie ${name}:`, error);
    return null;
  }
};
