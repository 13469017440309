import { FormEvent, useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { PAGE_ROUTES } from '~/constants/pages';
import { FuelContext } from '~/providers/FuelProvider';
import { Address, ServiceabilityResponse } from '~/types/address';
import { useDispatch, useSelector } from 'react-redux';
import { resetAddressRoute, setAddress, setServiceabilityResults, setUnservReason } from '~/redux/actions/address';
import { ReduxState } from '~/types/redux';
import useFetch from '~/hooks/useFetch';
import {
  removeToken,
  removeACP,
  setPageLoading,
  setResume,
  setCookiePass,
  setBuyflowRedirectDelay,
} from '~/redux/actions/common';
import { Tracking } from '~/types/common';
import { setNewRelicPageAction, setTrackingCustomAttributes } from '~/helpers/newrelic';
import { ZIPLY_UNSERVE } from '~/constants/address';
import isNurtureCampaign, { nurtureRedirect } from '~/helpers/checkNurtureCampaign';
import { DEFAULT_API_VERSION } from '~/constants/config/common';
import { setNotifications } from '~/redux/actions/global';
import Cookie from 'js-cookie';

interface ReturnType {
  apiVersion: string;
  selectedAddress: Address;
  installAddress: Address | Record<string, unknown>;
  setSelectedAddress: (address: Address) => void;
  handleFormSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleInputChange: (event: FormEvent<HTMLInputElement>) => void;
  buttonState: {
    disabled: boolean;
    loading: boolean;
  };
  tracking: Tracking;
  addressHistory: string[];
}

interface ServiceType {
  token: string;
  address?: Address;
  addressKey?: string;
  env?: string;
  controlNumber?: string;
}

const useAddressCheckPage = (): ReturnType => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { address, token, tracking, acp, addressHistory, monarch, landingPage, unservReason } = useSelector(
    (store: ReduxState) => store
  );
  const [selectedAddress, setSelectedAddress] = useState<Address>({
    addressKey: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    parsedAddress: {},
  });
  const { router, trackEvent } = useContext(FuelContext);
  const dispatch = useDispatch();

  setTrackingCustomAttributes(tracking);

  const apiVersion = (monarch?.apiVersion as string) || DEFAULT_API_VERSION;
  const {
    data,
    loading: runServiceabilityLoading,
    fetch: runServiceability,
  } = useFetch<ServiceabilityResponse>(`${apiVersion}/serviceability`, {
    method: 'post',
  });

  const runService = ({ token, address, addressKey, env, controlNumber }: ServiceType) => {
    if (unservReason) {
      dispatch(setUnservReason(''));
    }

    if (address?.state && address.state in ZIPLY_UNSERVE) {
      dispatch(setUnservReason(`address is in ${Object.keys(ZIPLY_UNSERVE)}`));
      isNurtureCampaign(router.query) ? nurtureRedirect() : router.push(PAGE_ROUTES.UNSERVICEABLE_ZIPLY);
    } else {
      let body = {};
      if (address) {
        body = {
          token,
          addressKey: address.addressKey,
          address: {
            address: address.address,
            address2: address.address2,
            city: address.city,
            state: address.state,
            postalCode: address.zip,
          },
        };
      } else if (env && controlNumber) {
        body = {
          addressKey,
          e: env,
          c: controlNumber,
        };
      }
      runServiceability({
        body: JSON.stringify(body),
      });
    }
  };

  //set zip cookie for preamp
  Cookie.set('zip', address?.install?.zip ?? 'none');

  useEffect(() => {
    if (landingPage?.persisted === false) {
      trackEvent({
        action: 'elementViewed',
        data: {
          elementType: 'CART STATE',
          location: 'address_check_page',
          position: 'PERSISTED FROM MLP',
          text: 'DIFF ADDRESS',
        },
      });
    }
  }, [landingPage?.persisted]);

  // ACP or Cookie pass flow.
  useEffect(() => {
    let timeoutId = null;

    if (monarch.apiVersion && !monarch?.enabled) {
      const acpFlow = acp?.env && acp?.controlNumber;
      const cookiePassFlow = token && address?.install;

      // We will need to wait on Monarch to respond so we need to throw up a loader for slow requests.
      if (acpFlow || cookiePassFlow) {
        dispatch(setResume('resumeEnabled', false));
        dispatch(setResume('acceptedResume', false));
        dispatch(setPageLoading(true));
        timeoutId = setTimeout(() => {
          dispatch(setPageLoading(false));
        }, 5000);
      } else {
        dispatch(setCookiePass(false));
      }

      if (acpFlow) {
        clearTimeout(timeoutId);
        runService({ token, ...acp });
        dispatch(removeToken());
        dispatch(removeACP());
      } else if (cookiePassFlow) {
        clearTimeout(timeoutId);
        runService({ token, address: address?.install });
        dispatch(removeToken());
        dispatch(removeACP());
      }
    }

    // If the component unmounts, clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [monarch.apiVersion, monarch.determineResumeQuote]);

  // Update address if updated in the redux state.
  useEffect(() => {
    if (!runServiceabilityLoading && address?.install?.address) {
      setSelectedAddress(address?.install);
    }
  }, [address?.install?.address]);

  // Fetch callbacks.
  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        const recaptchaToken = await executeRecaptcha();
        dispatch(setServiceabilityResults(data, router, apiVersion, recaptchaToken));
      }
    };
    fetchData();
  }, [data]);

  // Prefetch the next page chunk.
  useEffect(() => {
    router.prefetch(PAGE_ROUTES.PLAN_PACKAGE);
  }, []);

  // Setting the buyflow redirect delay. Doing this as separate state outside of Monarch
  // so it doesn't get overridden on a page change and the value is ready immediately on pageload
  // on the Confirmation page.
  useEffect(() => {
    if (typeof monarch?.buyflowRedirectDelay === 'number') {
      dispatch(setBuyflowRedirectDelay(monarch.buyflowRedirectDelay));
    }
  }, [monarch?.buyflowRedirectDelay]);

  // Event Handlers
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(resetAddressRoute());
    // Hides error banner when running new address
    dispatch(setNotifications());

    trackEvent({
      action: 'elementClicked',
      data: {
        text: 'Check availability',
      },
      event,
    });
    setNewRelicPageAction('addressFormSubmitClicked');

    dispatch(setAddress('install', selectedAddress));

    const token = await executeRecaptcha();

    runService({ token, address: selectedAddress });
  };

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    setSelectedAddress({
      ...selectedAddress,
      [name]: value,
    });
  };

  return {
    apiVersion,
    selectedAddress,
    installAddress: address?.install || {},
    setSelectedAddress,
    handleFormSubmit,
    handleInputChange,
    buttonState: {
      disabled: !selectedAddress.addressKey,
      loading: runServiceabilityLoading,
    },
    tracking,
    addressHistory,
  };
};

export default useAddressCheckPage;
