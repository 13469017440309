import { ActionTypes, QuizAction, QuizSelectedAnswers, QuizResults, QuizResetAction } from '~/types/quiz';
import { FormStepResults } from '@redventures/odyssey';

export const setQuiz = (key: string, value: QuizSelectedAnswers | QuizResults | string | number | boolean | FormStepResults): QuizAction => {
	return { type: ActionTypes.SET_QUIZ, key, value };
};

export const resetQuiz = (): QuizResetAction => {
  return { type: ActionTypes.RESET_QUIZ };
};
