import { useEffect, useState, MutableRefObject } from 'react';

/**
 * Hook to detect if an element is in the viewport
 * @param ref - reference to the element to check if it is in the viewport
 * @param threshold - number between 0 and 1 representing the percentage of the element that must be in the viewport to be considered in view
 * @param fireOnce - if true, the hook will only fire once when the element enters the viewport and won't reset when it leaves
 * @returns boolean indicating if the element is in the viewport
 */
export default function useIsInViewport(
  ref: MutableRefObject<HTMLElement>,
  threshold = 0,
  fireOnce = false
): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsIntersecting(true);
            if (fireOnce) {
              observer.disconnect();
            }
          } else if (!fireOnce) {
            setIsIntersecting(false);
          }
        },
        { threshold }
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        observer.disconnect();
      };
    } else {
      console.warn('IntersectionObserver is not supported in this environment.');
      setIsIntersecting(false);
    }
  }, [ref, threshold, fireOnce]);

  return isIntersecting;
}
