import React, { FC, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { Provider as ReduxProvider } from 'react-redux';
import { NextRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from '../styles';
import { Store } from 'redux';
import { setMonarchDecision, setPageLoading } from '~/redux/actions/common';
import { trackEvent } from '~/helpers/tracking';
import { EventData } from '~/types/events';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { setNotifications } from '~/redux/actions/global';
import { DEFAULT_FUSE_NUMBER } from '~/constants/config/cohesion';
import clientSide from '~/helpers/clientSide';
import { requestMonarch } from '@red-digital/bricks';
import { DEFAULT_API_VERSION, MONARCH_RULESETS } from '~/constants/config/common';
import Cookies from 'js-cookie';

const Debugger = dynamic(() => import('../components/Debugger'), { ssr: false });

interface FuelContextProps {
  router: NextRouter;
  production: boolean;
  trackEvent: ({ action, data, event }: EventData) => void;
  fuseNumber: string;
}

interface FuelProviderProps {
  children: JSX.Element[] | JSX.Element;
  store: Store;
  production: boolean;
  router: NextRouter;
}

export const FuelContext = React.createContext<FuelContextProps | null>(null);

const FuelProvider: FC<FuelProviderProps> = ({ children, store, router, production }) => {
  const [fuseNumber, setFuseNumber] = useState(null);

  // Remove the loader when the route changes.
  if (router?.events?.on) {
    router.events.on('routeChangeComplete', (url, options) => {
      const shallow = options?.shallow ?? false;

      if (url.includes('/confirmation')) {
        trackEvent({
          action: 'elementViewed',
          data: {
            elementType: 'PAGE TRANSITION',
            location: '/confirmation',
            position: 'TRANSITION START',
            text: 'tracking?.redirectUrl',
          },
        });
      }
      if (!shallow && !url.includes('/confirmation')) {
        store.dispatch(setNotifications());
        store.dispatch(setPageLoading(false));
      }
    });
  }

  const monarchCallback = (err: unknown, monarchResponse: unknown) => {
    if (typeof monarchResponse === 'object') {
      const rules = Object.entries(monarchResponse || {});
      const valueMap = { apiVersion: DEFAULT_API_VERSION };

      if (rules.length) {
        rules.forEach(([key, returnValue]) => (valueMap[key] = returnValue));
        store.dispatch(setMonarchDecision(valueMap));
      }
    }
  }

  useEffect(() => {
    window?.cohesion('fuse:leasesReceived', (leases) => {
      setFuseNumber(leases?.[0]?.dnis);
    });

    window?.cohesion('fuse:error', () => setFuseNumber(DEFAULT_FUSE_NUMBER));
  }, []);

  useEffect(() => {
    if (clientSide) {
      const olVersionMlp = window?.sessionStorage?.getItem('olVersion');
      const apiVersionCookie = Cookies.get('apiVersion');
      const olVersion = apiVersionCookie ? apiVersionCookie : olVersionMlp;

      window?.cohesion('monarch:ready', () => {
        requestMonarch({method: 'ruleset', id: MONARCH_RULESETS.GLOBAL, payload: {ol_version: olVersion}, callback: monarchCallback});
      });

    }
  }, [router.query])

  return (
    <ReduxProvider store={store}>
      <GoogleReCaptchaProvider useEnterprise reCaptchaKey={process.env.recaptchaSiteKey}>
        <FuelContext.Provider
          value={{
            production,
            router,
            trackEvent,
            fuseNumber,
          }}
        >
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            {children}
            {!production && <Debugger />}
          </ThemeProvider>
        </FuelContext.Provider>
      </GoogleReCaptchaProvider>
    </ReduxProvider>
  );
};

FuelProvider.displayName = 'FuelProvider';

export default FuelProvider;
