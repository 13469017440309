import { CohesionProvider } from '@redventures/cohesion-utils-react';
import { ComponentType } from 'react';

export const withCohesion = ({ App, assets }: { App: ComponentType; assets: Record<string, ComponentType> }) => {
  const CohesionProviderHOC = (props) => (
    <CohesionProvider components={assets}>
      <App {...props} />
    </CohesionProvider>
  );

  CohesionProviderHOC.displayName = 'CohesionProviderHOC';
  return CohesionProviderHOC;
};
