import assign from 'lodash/assign';
import common from '~/redux/reducers/common';
import address from '~/redux/reducers/address';
import billing from '~/redux/reducers/billing';
import contactCredit from '~/redux/reducers/contact-credit';
import products from '~/redux/reducers/products';
import appointment from '../reducers/appointment';
import debug from '~/redux/reducers/debug';
import quiz from '~/redux/reducers/quiz';
import legal from '~/redux/reducers/legal';
import { Reducers } from '~/types/redux';
import clientSide from '~/helpers/clientSide';
import recommendation from '../reducers/recommendation';
import enrichedCustomer from '../reducers/enrichedCustomer';
import discoveryQuestions from '../reducers/discoveryQuestions';
const combineReducers = (reducers: Reducers) => {
  const reducersList = Object.values(reducers);

  const combination = (state = {}, action) => {
    let nextState = { ...state };

    // Loop through all passed reducers and create new state
    reducersList.forEach((reducer) => {
      const newState = reducer(nextState, action);
      nextState = assign(nextState, newState);
    });

    if (clientSide) {
      window.sessionStorage.setItem('persist:fuel', JSON.stringify(nextState));
    }

    return nextState;
  };

  return combination;
};

const reducers = combineReducers({
  common,
  address,
  billing,
  contactCredit,
  products,
  appointment,
  debug,
  quiz,
  legal,
  recommendation,
  enrichedCustomer,
  discoveryQuestions,
});

export default reducers;
