import styled, { css } from 'styled-components';

export const HeaderStyled = styled.header`
  ${({ theme, showHeader, isRedHeader }) => {
    const { media, colors } = theme;

    return css`
      opacity: ${showHeader ? 1 : 0} !important;
      align-items: center;
      background: ${isRedHeader ? colors.primary.red : colors.primary.white};
      display: flex;
      padding: ${isRedHeader ? '10px 0' : '20px 0'};
      position: relative;
      width: 100%;

      ${media.tablet.up} {
        padding: ${isRedHeader ? '10px 0' : '25px 0'};
      }

      .icon--frontier-logo {
        color: ${isRedHeader ? colors.primary.white : colors.primary.red};
      }

      .progress-bar {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .checkout {
        &__back {
          width: 32px;
          height: 32px;
          margin-right: 10px;
          transform: rotate(180deg);
          cursor: pointer;
          &:hover {
            color: ${colors.primary.red};
          }
        }

        &__container {
          display: flex;
          align-items: center;
          position: relative;
          margin-left: auto;
          margin-right: auto;
          gap: 8px;
          font-weight: 700;
          font-size: 19px;
          color: ${colors.primary.red};
        }

        &__frontier-icon {
          height: 20px;
          width: 20px;
        }
      }

      .container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;

        .eero-logo {
          padding-left: 32px;
        }

        .quoteNumber {
          font-size: 12px;
          font-weight: 500;
          margin-left: auto;
        }

        .quiz__exit {
          color: ${colors.primary.darkBlue};
          display: flex;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          font-weight: 500;
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: inherit;

          .icon {
            height: 20px;
            margin-left: 10px;

            path {
              fill: ${colors.primary.darkBlue};
            }
          }
        }
      }
    `;
  }};
`;
