import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '~/types/redux';
import { questions, planMap, addOnMap } from '~/components/Quiz/data';
import { setQuiz } from '~/redux/actions/quiz';
import { QuizQuestion, QuizResults, QuizSelectedAnswers } from '~/types/quiz';
import { useRouter } from 'next/router';
import { PAGE_ROUTES } from '~/constants/pages';
import { Product, SelectedProductsState } from '~/types/products';
import { trackEvent } from '~/helpers/tracking';
import { getSite } from '~/helpers/site';
import { IDENTITY_PROTECTION } from '~/constants/entityIds';
import { setNewRelicCustomAttribute } from '~/helpers/newrelic';

interface ReturnType {
  showMovers: boolean;
  setShowMovers: (arg0: boolean) => void;
  showQuestions: boolean;
  setShowQuestions: (arg0: boolean) => void;
  questions: QuizQuestion[];
  resetQuiz: () => void;
  exitQuiz: () => void;
  orderSelectedAddOns: (selectedState: SelectedProductsState) => Product[];
}

const useMyCustomQuote = (): ReturnType => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [baseUrl, setBaseUrl] = useState('/');
  const [showMovers, setShowMovers] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const [userEntry, setUserEntry] = useState<string | null>(null);

  const { address, products, quiz, pageLoading, notifications } = useSelector((store: ReduxState) => store);
  const { quizStarted, quizTotal, answers, seenResults, submissionCount } = quiz;

  const resetQuiz = () => {
    trackEvent({
      action: 'elementClicked',
      data: {
        text: 'RESTART QUIZ',
        elementType: 'BUTTON',
        position: 'GDT',
        location: 'RESULTS PAGE',
        action: 'INTERNALLINK',
      },
    });

    dispatch(setQuiz('currentQuestionIndex', 0));
    dispatch(setQuiz('answers', {}));
    dispatch(setQuiz('results', {}));
    dispatch(setQuiz('quizTotal', -1));

    router.replace(PAGE_ROUTES.MY_CUSTOM_QUOTE);
  };

  const exitQuiz = () => {
    dispatch(setQuiz('currentQuestionIndex', 0));
    dispatch(setQuiz('answers', {}));
    dispatch(setQuiz('results', null));
    dispatch(setQuiz('quizTotal', -1));
    dispatch(setQuiz('quizStarted', false));
    dispatch(setQuiz('quizSession', false));
    dispatch(setQuiz('seenResults', false));
    dispatch(setQuiz('submissionCount', 0));

    trackEvent({
      action: 'elementClicked',
      data: {
        text: 'EXIT QUIZ',
        elementType: 'BUTTON',
        position: 'GDT',
        location:
          quiz?.currentQuestionIndex < questions.length - 1
            ? `QUESTION #${quiz?.currentQuestionIndex + 1}`
            : `RESULTS PAGE`,
        action: 'EXTERNALLINK',
      },
    });

    // Send user back to plan-package page if they entered quiz from gdtBanner
    if ( userEntry === 'gdtBanner' ) {
      router.push(PAGE_ROUTES.PLAN_PACKAGE);
    } else {
      // Send user back to landing page where they entered the quiz from
      window.location.href = `${baseUrl}?gdt`;
    }
  };

  const orderSelectedAddOns = (selectedState: SelectedProductsState) => {
    const list = [];

    if (selectedState?.vas) {
      Object.keys(selectedState?.vas).forEach((addOn) => {
        list.push(selectedState?.vas[addOn]);
      });
    }

    if (selectedState?.voice) list.push(selectedState?.voice);

    if (selectedState?.video) {
      Object.keys(selectedState?.video).forEach((addOn) => {
        list.push(selectedState?.video[addOn]);
      });
    }

    if (selectedState?.secure) {
      Object.keys(selectedState?.secure).forEach((addOn) => {
        // add nested family plan addon
        if (
          selectedState?.secure[addOn]?.productId === IDENTITY_PROTECTION &&
          selectedState?.secure[addOn]?.configurations?.length
        ) {
          list.push({
            displayName: 'Identity Protection with Family Plan',
            price: {
              amount:
                selectedState?.secure[addOn]?.price?.amount +
                selectedState?.secure[addOn]?.additionalOptions[0]?.price?.amount,
            },
          });
        } else if (
          selectedState?.secure[addOn]?.configurations?.length &&
          selectedState?.secure[addOn]?.additionalOptions[0]?.amount > 0
        ) {
          // Push parent VAS product onto array
          list.push(selectedState?.secure[addOn]);
          const additionalOptionAmount = selectedState?.secure[addOn]?.additionalOptions[0]?.amount;

          // Handle VAS product's additional options
          // This code repeats the list.push() call for each additional option selected
          // i.e. 3 additional extenders selected with WHWF+ -> call list.push() 3 times
          Array.from({length: additionalOptionAmount}, () => list.push({
            displayName: selectedState?.secure[addOn]?.additionalOptions[0]?.displayName,
            price: {
              amount:
                selectedState?.secure[addOn]?.additionalOptions[0]?.price?.amount
            },
          }));
        } else {
          list.push(selectedState?.secure[addOn]);
        }
      });
    }
    return list;
  };

  const calculateAddOns = (answers: QuizSelectedAnswers, internet: string) => {
    const addOns = [];

    Object.keys(answers).forEach((index) => {
      if (answers[index].isVAS) {
        const { selected } = answers[index];
        // Grab speed of recommended product to find non-default add-ons
        const recProductSpeed = products?.available?.broadband?.find((p) => p.productId === internet)?.dataspeed
          ?.downloadInMbps;

        selected.forEach((selectedIndex: number) => {
          // Handle different addOn per plan (ex: Wi-Fi Secure powered by eero vs homeshield elite for 5gig)
          const addOnArray =
            questions[index].answerChoices[selectedIndex].addOn[recProductSpeed] ||
            questions[index].answerChoices[selectedIndex].addOn.default;
          addOnArray?.forEach((addOn) => {
            // Remove home phone for copper
            if (internet === 'f9a6b7fa-25b4-40bd-bf90-c53d7415d9fa' && addOn === 'home-phone-line') return;
            addOns.push(addOn);
          });
        });
      }
    });

    // sort add ons based on hierarchal rank
    const sorted = addOns.sort((a, b) => addOnMap[a].rank - addOnMap[b].rank);
    // remove duplicate add ons
    const uniqueAddOns = sorted.filter((item, index) => sorted.indexOf(item) === index);
    // convert addOns to their productId
    const addOnIds = uniqueAddOns.map((addOn) => addOnMap[addOn].productId);
    // recommend 3 add ons maximum
    return addOnIds.slice(0, 3);
  };

  const calculateQuizResults = (): QuizResults => {
    let internet = '';

    // Handles copper and fiber30 eligibility (only one product available)
    if (products?.broadbandType === 'COPPER' || products?.available?.broadband?.length === 1) {
      internet = products?.available?.broadband[0].productId;
    } else {
      Object.keys(planMap).forEach((plan) => {
        if (quizTotal >= planMap[plan].min && quizTotal < planMap[plan].max) {
          internet = planMap[plan].productId;
          return;
        }
      });
    }

    const addOns = calculateAddOns(answers, internet);

    return {
      internet,
      addOns,
    };
  };

  useEffect(() => {
    setNewRelicCustomAttribute([{ name: 'isGDT', value: 'true' }]);
  }, []);

  useEffect(() => {
    /*
     * FRIN's homepage is /ps/
     * INFR and FRDC's homepage is /
     */
    const { isFRIN } = getSite();

    if (isFRIN) setBaseUrl('/ps/');
  }, []);

  useEffect(() => {
    // Show movers question once serviceability has been run
    if (address?.install?.addressKey && address?.install?.env && address?.install?.controlNumber && !quizStarted)
      setShowMovers(true);
  }, [address?.install?.addressKey, address?.install?.env, address?.install?.controlNumber, quizStarted]);

  useEffect(() => {
    // Skip service check for users that have saved progress on the quiz
    if (!showQuestions && quizStarted && !notifications?.length) setShowQuestions(true);
  }, [quizStarted]);

  useEffect(() => {
    // If error banner displays, hide questions and return to address check screen
    if (notifications?.length) {
      dispatch(setQuiz('quizStarted', false));
      setShowQuestions(false);
      setShowMovers(false);
    }
  }, [notifications]);

  useEffect(() => {
    // Do not send to results page until getProducts is returned
    if (pageLoading) return;

    // Calculate results after quiz is submitted
    // Checking greater than -1 because some answer combinations give a negative total (never smaller than -1)
    if (quizTotal > -1 && !seenResults) {
      const calculated = calculateQuizResults();
      dispatch(setQuiz('results', calculated));

      router.push(PAGE_ROUTES.MY_CUSTOM_QUOTE_PLAN_PACKAGE);
    }
  }, [quizTotal, pageLoading, submissionCount]);

  useEffect(() => {
    // Handles hiding the movers question if a user submits a second address
    if (pageLoading) setShowMovers(false);
  }, [address?.install?.addressKey]);

  useEffect(() => {
    const { entry } = router.query;
    if ( entry ) {
      setUserEntry(entry as string);
    }
    }, [router.query]);

  return {
    showMovers,
    setShowMovers,
    showQuestions,
    setShowQuestions,
    questions,
    resetQuiz,
    exitQuiz,
    orderSelectedAddOns,
  };
};

export default useMyCustomQuote;
