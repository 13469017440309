import React, { SVGProps } from 'react';

const Television = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-bundle-premium"
      {...props}
    >
      <path d="M6.375 1C3.61358 1 1.375 3.23858 1.375 6V14C1.375 14.3425 1.40943 14.6769 1.47502 15H3.375V6C3.375 4.34315 4.71815 3 6.375 3H18.375C20.0319 3 21.375 4.34315 21.375 6V15H23.275C23.3406 14.6769 23.375 14.3425 23.375 14V6C23.375 3.23858 21.1364 1 18.375 1H6.375Z" />
      <path d="M22.3754 17H2.37463C3.28684 18.2144 4.73918 19 6.375 19H11.375V21H5.375V23H19.375V21H13.375V19H18.375C20.0108 19 21.4632 18.2144 22.3754 17Z" />
      <path d="M18.375 5.5L11.375 12.5L12.875 14L19.875 7L18.375 5.5Z" />
      <path d="M4.875 12.5L11.875 5.5L13.375 7L6.375 14L4.875 12.5Z" />
    </svg>
  );
};

Television.displayName = 'Television';

export default Television;
