import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { DEFAULT_FUSE_NUMBER } from '~/constants/config/common';
import clientSide from '~/helpers/clientSide';
import { getSiteKey } from '~/helpers/site';
import { setTrackingCustomAttributes, setNewRelicCustomAttribute, setNewRelicPageAction } from '~/helpers/newrelic';
import { formatPhone } from '~/helpers/phone';
import { setCallInNumber, setTracking } from '~/redux/actions/common';
import Cookies from 'js-cookie';
import LogRocket from 'logrocket';

type Tracking = {
  [key: string]: string;
};

declare global {
  interface Window {
    _Cohesion: {
      anonymousId: string;
      sessionId: string;
      webContext: {
        page: {
          referrer: string;
        };
      };
      tenantId: string;
    };
    _Monarch: {
      evaluations?: {
        source?: {
          source?: {
            trafficFlow?: {
              value?: {
                experienceNumber?: string;
              }
            }
          }
        }
      };
    };
    fuse: (meta: string, data: Record<string, unknown>) => void;
    isBot: () => boolean;
  }
}

const useCohesion = (dispatch: Dispatch, tracking: Tracking): void => {
  const site = getSiteKey();
  const defaultFuseNumber = DEFAULT_FUSE_NUMBER[site] || '';

  useEffect(() => {
    if (clientSide) {
      window?.cohesion('ready', () => {
        const { anonymousId, sessionId, webContext, tenantId } = window?._Cohesion;
        const experienceNumber = window?._Monarch?.evaluations?.source?.source?.trafficFlow?.value?.experienceNumber; // Grabs the experience number from Preamp

        LogRocket.identify(tracking.clientSessionId);
        LogRocket.track('custom_tracking', {
          cart_site: `Fuel Cart - ${getSiteKey()}`,
          sessionId: sessionId,
          experienceNumber: experienceNumber,
          domain_str: window?.location.hostname,
          releaseTag_str: process.env.release,
        });

        dispatch(setTracking('anonymousId', anonymousId));
        dispatch(setTracking('sessionId', sessionId));
        dispatch(setTracking('tenantId', tenantId));
        dispatch(setTracking('referrer', webContext.page.referrer));
        setTrackingCustomAttributes(tracking, true);

        if (window?.isBot()) {
          dispatch(setCallInNumber(defaultFuseNumber));
          setNewRelicCustomAttribute([{ name: 'BotDetected', value: 'true' }]);
        }
      });

      window?.cohesion('fuse:leasesReceived', (leases) => {
        const amex = Cookies.get('isAmexOffer') === 'true';
        if (!amex) {
          if (leases[0]?.dnis) {
            dispatch(setCallInNumber(formatPhone(leases[0].dnis)));
          } else {
            dispatch(setCallInNumber(defaultFuseNumber));
          }
        }
      });

      window?.cohesion('fuse:error', (err) => {
        dispatch(setCallInNumber(defaultFuseNumber));
        setNewRelicCustomAttribute([{ name: 'FuseError', value: err }]);
        setNewRelicPageAction('fuse:error');
      });
    }
  }, []);
};

export default useCohesion;
