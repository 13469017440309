import { ReduxState } from '~/types/redux';
import assign from 'lodash/assign';
import { Actions, ActionTypes } from '~/types/recommendation';

const recommendation = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_RECOMMENDATION:
      return assign({}, state, {
        recommendation: {
          ...state.recommendation,
          bundle: action.productRecommendation.bundle,
          recommendedSpeedPlan: action.productRecommendation.recommendedSpeedPlan,
          recommendedVas: action.productRecommendation.recommendedVas,
          alternativeSpeedPlans: action.productRecommendation.alternativeSpeedPlans,
        },
      });
    default:
      return state;
  }
};

export default recommendation;
