import { BaseResponse } from './common';
import { LegalMap } from './legal';
import { Feature, Product } from './products';

export interface GdtAnswer {
  question: string;
  questionId: string;
  answers: string[];
}

export interface DisplayInformation {
  name: string;
  displayName?: string;
  sweeteners?: Record<string, unknown>[];
  features: Feature[];
  speedAbbreviation?: {
    value: number;
    unit: string;
  };
}

export interface Bullet {
  isPositive: boolean;
  bullet: string;
}

export interface Insight {
  productName: string;
  explanation: string;
  rationale: string;
  dimension: 'speed' | 'security' | 'entertainment' | 'coverage';
  bullets: Bullet[];
  score: number;
  callout: string;
  tooltip?: string;
  label: string;
  rationaleHtml?: string;
}

export interface BundleDetails {
  sentence: string;
  paragraph: string;
  profileName: string;
  traits: string[];
  insights: Insight[];
}

export interface RecommendedSpeedPlan {
  product: Product;
  percentMatch: number;
  bullets: Bullet[];
  displayInfo: DisplayInformation;
  explanation: string;
  explanationOneLiner: string;
  speedScore: number;
}

export interface AlternativeSpeedPlan {
  productId: string;
  productName: string;
  percentMatch: number;
  bullets: Bullet[];
  displayInfo: DisplayInformation;
  paragraph: string;
  speedScore: number;
}

export interface Vas {
  product: Product;
  bullets: Bullet[];
}

export interface Recommendation {
  bundle: BundleDetails;
  recommendedSpeedPlan: RecommendedSpeedPlan;
  recommendedVas: Vas[];
  alternativeSpeedPlans: AlternativeSpeedPlan[];
}

export interface ProductRecommendationResponse extends BaseResponse, Recommendation {
  availableProducts: Product[];
  legal: LegalMap;
}

export enum ActionTypes {
  SET_RECOMMENDATION = 'recommendation/set',
}

export interface SetRecommendationAction {
  type: ActionTypes.SET_RECOMMENDATION;
  productRecommendation: ProductRecommendationResponse;
}

export type Actions = SetRecommendationAction;
