import React, { FC, useEffect } from 'react';
import ModalStyled from './ModalStyled';
import { handleEscape } from '../../helpers/modalHelpers';

interface Props {
  visible: boolean;
  zIndex?: number;
  handleOnClose?: (key: string) => void;
  disableClose?: boolean;
}

const Modal: FC<Props> = ({ visible, handleOnClose, children, zIndex, disableClose = false }) => {
  useEffect(() => {
    if (visible && !disableClose) handleEscape(handleOnClose);
  }, [visible]);

  function modalClicked(e) {
    if (e.currentTarget !== e.target || disableClose) return; // Ensure the event isn't bubbling up from somewhere inside the modal
    handleOnClose && handleOnClose(undefined);
  }

  return (
    visible && (
      <ModalStyled data-disable-document-scroll={visible} visible={visible} onClick={modalClicked} zIndex={zIndex}>
        {children}
      </ModalStyled>
    )
  );
};

Modal.displayName = 'Modal';

export default Modal;
