/** Pairs with the Cohesion Utils package. The <MonarchPreampPlacement> componentId prop is a function that passes the asset and needs the componentId returned */
export const getBaseAssetName = (asset) => {
  let finalName;
  if (asset && asset.assetName) {
    const { assetName = '' } = asset;
    finalName = assetName.includes('@') ? assetName.split('@')[0].trimEnd() : assetName;
  }

  return finalName;
};
