import { FC, Fragment } from 'react';
import config from '~/constants/config';

const { ac } = config;

const Allconnect: FC = () => (
  <Fragment>
    <link rel="preload" as="script" href={process.env.acCohesionUrl} />
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
        !(function (co, h, e, s, i, o, n) {
        var d = 'documentElement';
        var a = 'className';
        h[d][a] += ' preampjs fusejs';
        n.k = e;
        co._Cohesion = n;
        co._Preamp = { k: s, start: new Date() };
        co._Fuse = { k: i };
        co._Tagular = { k: o };
        [e, s, i, o].map(function (x) {
          co[x] = co[x] || function () {
            (co[x].q = co[x].q || []).push([].slice.call(arguments));
          };
        });
        var b = function () {
            var u = h[d][a];
            h[d][a] = u.replace(/ ?preampjs| ?fusejs/g, '');
          };
        h.addEventListener('DOMContentLoaded', function () {
          co.setTimeout(b, 3e3);
          co._Preamp.docReady = co._Fuse.docReady = !0;
        });
        var z = h.createElement('script');
        z.async = 1;
        z.src = '${process.env.acCohesionUrl}';
        z.onerror = function () {
          var ce = 'error',
            f = 'function';
          for (var o of co[e].q || []) o[0] === ce && typeof o[1] == f && o[1]();
          co[e] = function (n, cb) {
            n === ce && typeof cb == f && cb();
          };
          b();
        };
        h.head.appendChild(z);
      })(window, document, 'cohesion', 'preamp', 'fuse', 'tagular', {
        tagular: {
          apiKey: '${ac.apiKey}' || '',
          writeKey: '${ac.writeKey}' || '',
          sourceKey: '${ac.sourceKey}' || '',
          apiVersion: 'v2/t',
          taggy: {
            enabled: false
          }
        },
        preamp: {
          siteId: '${ac.siteId}' || '',
          hooks: {},
        },
        monarch: {
          token: '${ac.token}' || '',
          sourceId: '${ac.sourceId}' || '',
          endpoint: '${process.env.acMonarchDomain}',
          autoRequest: {
            enabled: true,
            suppressPlacements: false,
          },
        },
        consent: {
          required: true
        },
        fuse: {
          leases: [{
            pool: '${ac.fuseLease}',
          }],
          metadata: {
            vdn: '51054'
          }
        }
      });
      `,
      }}
    />
  </Fragment>
);

Allconnect.displayName = 'Cohesion';

export default Allconnect;
