import { ReduxState } from '~/types/redux';
import assign from 'lodash/assign';
import { Actions, ActionTypes } from '~/types/gdtFlow';

const discoveryQuestions = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_DISCOVERY_QUESTIONS:
      return assign({}, state, {
        discoveryQuestions: action.discoveryQuestions,
      });
    default:
      return state;
  }
};

export default discoveryQuestions;
