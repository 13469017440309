import { NextRouter } from 'next/router';
import { Dispatch } from 'redux';
import { PAGE_ROUTES } from '~/constants/pages';
import { EXTERNAL_PAGE_ROUTES } from '~/constants/external-pages';
import { setNewRelicCustomAttribute } from '~/helpers/newrelic';
import {
  ActionTypes,
  AddressAction,
  UpdateAddressAction,
  Address,
  EligibilityAction,
  Eligibility,
  Promotion,
  PromotionsAction,
  ResetAddressRouteAction,
  AddressHistoryAction,
  ServiceabilityResponse,
  UnservReasonAction,
  SetVoiceEligibleAction,
  UnservRedirectAction,
} from '~/types/address';
import { ReduxState } from '~/types/redux';
import { setPageLoading } from './common';
import { setNotifications } from './global';
import { getProducts, setOfferType } from './products';
import isNurtureCampaign, { nurtureRedirect } from '~/helpers/checkNurtureCampaign';
import { routeToExternal } from '~/helpers/routeToExternal';
import { isFutureFiber } from '~/helpers/isFutureFiber';
import { trackEvent } from '~/helpers/tracking';
import Cookie from 'js-cookie';
import checkUnservRedirectEnabled from '~/helpers/checkUnservRedirectEnabled';

export const setAddress = (key: string, address: Address): AddressAction => {
  return { type: ActionTypes.SET_ADDRESS, key, address };
};

export const updateAddress = (key: string, address: Address): UpdateAddressAction => {
  return { type: ActionTypes.UPDATE_ADDRESS, key, address };
};

export const setEligibility = (eligibility: Eligibility): EligibilityAction => {
  return { type: ActionTypes.SET_ELIGIBILITY, eligibility };
};

export const setPromotions = (promotions: Promotion[]): PromotionsAction => {
  return { type: ActionTypes.SET_PROMOTIONS, promotions };
};

export const resetAddressRoute = (): ResetAddressRouteAction => {
  return { type: ActionTypes.RESET_ADDRESS_ROUTE };
};

export const addAddressHistory = (addressKey: string): AddressHistoryAction => {
  return { type: ActionTypes.ADDRESS_HISTORY, addressKey };
};

export const setUnservReason = (unservReason: string): UnservReasonAction => {
  return { type: ActionTypes.SET_UNSERV_REASON, unservReason };
};

export const setUnservRedirect = (unservRedirect: Record<string, string>): UnservRedirectAction => {
  return { type: ActionTypes.SET_UNSERV_REDIRECT, unservRedirect };
};

export const setVoiceEligible = (voiceEligible: boolean): SetVoiceEligibleAction => {
  return { type: ActionTypes.SET_VOICE_ELIGIBLE, voiceEligible };
};

export const setServiceabilityResults = (
  response: ServiceabilityResponse,
  router: NextRouter,
  apiVersion: string,
  recaptchaToken: string | null,
  monarch?: ReduxState['monarch']
) => {
  return (dispatch: Dispatch, getState: () => ReduxState): void => {
    const {
      success,
      offerType,
      useUnserveSoft,
      addressHasExistingService,
      userErrorMessage,
      addressKey,
      env,
      controlNumber,
      inFootprint,
      fiberBuildOutStatus,
      futureServiceDate,
      unservReason,
      unservRedirect,
      designatedMarketArea,
      designatedMarketAreaDescription,
      tipDataFromZipCode,
      voiceEligible,
    } = response;
    // Set cookie for preamp. Always set because if a user tries a new address, we want to make sure the cookie is updated.
    Cookie.set('voiceEligible', Boolean(voiceEligible));

    if (voiceEligible) dispatch(setVoiceEligible(voiceEligible));
    if (unservReason) dispatch(setUnservReason(unservReason));
    if (designatedMarketArea) {
      dispatch(updateAddress('install', { dma: designatedMarketArea }));
      Cookie.set('dma', designatedMarketArea); // Set cookie for preamp
    }
    if (designatedMarketAreaDescription) {
      dispatch(updateAddress('install', { dmaName: designatedMarketAreaDescription }));
      Cookie.set('dmaName', designatedMarketAreaDescription); // Set cookie for preamp
    }

    if (tipDataFromZipCode) {
      Cookie.set('tipDataFromZipCode', JSON.stringify(tipDataFromZipCode)); // Set cookie for monarch
    }

    const isMovers = getState().monarch?.cartMovers;
    const isFutureFiberEligible = isFutureFiber(fiberBuildOutStatus, futureServiceDate);

    if (unservRedirect && !isFutureFiberEligible) {
      dispatch(setUnservRedirect(unservRedirect));
      Cookie.set('unservRedirectProvider', unservRedirect?.provider ?? '');

      const { pathname: currentPage } = router;
      const unservRedirectEnabled = checkUnservRedirectEnabled(currentPage);
      if (unservRedirectEnabled) {
        return;
      }
    }

    if (useUnserveSoft) {
      isNurtureCampaign(router.query) ? nurtureRedirect() : router.push(PAGE_ROUTES.UNSERVICEABLE_SOFT);
    } else if (inFootprint === false) {
      isNurtureCampaign(router.query) ? nurtureRedirect() : router.push(PAGE_ROUTES.UNSERVICEABLE_ALLCONNECT);
    } else if (fiberBuildOutStatus === 'PAL_NEEDED') {
      isNurtureCampaign(router.query)
        ? nurtureRedirect()
        : process.env.environment == 'production'
        ? routeToExternal(EXTERNAL_PAGE_ROUTES.PAL_NEEDED_PROD, env, controlNumber)
        : routeToExternal(EXTERNAL_PAGE_ROUTES.PAL_NEEDED_QA, env, controlNumber);
    } else if (isFutureFiberEligible) {
      // track wheres my fiber redirect
      trackEvent({
        action: 'elementClicked',
        data: {
          elementType: 'REDIRECT',
          position: 'WHERES_MY_FIBER',
          location: 'WHERES_MY_FIBER',
          action: 'EXTERNAL_LINK',
        },
      });

      routeToExternal(EXTERNAL_PAGE_ROUTES.WHERES_MY_FIBER, env, controlNumber);
    } else if (success) {
      if (addressKey) dispatch(updateAddress('install', { addressKey, env, controlNumber }));

      if (offerType) dispatch(setOfferType(offerType));

      if (addressHasExistingService) {
        dispatch(
          setEligibility({
            isMovers: false,
            addressHasExistingService,
            serviceable: false,
          })
        );
      } else if (isMovers) {
        dispatch(
          setEligibility({
            isMovers: true,
            addressHasExistingService,
            serviceable: true,
          })
        );
      } else {
        getProducts(router, false, apiVersion, '', monarch, recaptchaToken)(dispatch, getState);
      }
    } else {
      dispatch(setPageLoading(false));
      dispatch(
        setNotifications([
          {
            type: 'error',
            message:
              userErrorMessage ||
              `Sorry, we're having some technical difficulties. Call ##phoneNumber## and one of our sales experts will help you complete your order.`,
          },
        ])
      );
      setNewRelicCustomAttribute([{ name: 'serviceabilityError', value: 'true' }]);
    }
  };
};
