import React, { SVGProps } from 'react';

const UpDownArrows = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-bundle-premium"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.375 8.75L17.625 3.5L22.875 8.75L21.375 10.25L17.625 6.5L13.875 10.25L12.375 8.75ZM16.625 21H18.625L18.625 10H16.625L16.625 21ZM9.125 3.50001H7.125V14.5H9.125V3.50001ZM13.375 15.75L8.125 21L2.875 15.75L4.375 14.25L8.125 18L11.875 14.25L13.375 15.75Z"
      />
    </svg>
  );
};

UpDownArrows.displayName = 'UpDownArrows';

export default UpDownArrows;
