import {
  Arrows as ArrowsOutline,
  Ban as BanOutline,
  Bill as BillOutline,
  CheckCircle as CheckCircleOutline,
  CheckCircle,
  CircleUp as CircleUpOutline,
  Coin as CoinOutline,
  CompareBill,
  ConfirmationCheck as ConfirmationCheckOutline,
  DiagonalRight as DiagonalRightOutline,
  Download as DownloadOutline,
  Error as ErrorOutline,
  FrontierLogo,
  Gamepad as GamepadOutline,
  Gift as GiftOutline,
  Gift2 as Gift2Outline,
  GiftCard as GiftCard,
  Globe as GlobeOutline,
  InfoCircle as InfoCircleOutline,
  LaptopOpen as LaptopOpenOutline,
  PhoneMirrored as PhoneOutline,
  MagnifyingGlass,
  Money as MoneyOutline,
  Monitor as MonitorOutline,
  MultipleUsers as MultipleUsersOutline,
  ShieldIdentity as ShieldIdentityOutline,
  Star as StarOutline,
  Stopwatch as StopwatchOutline,
  StopwatchRed as StopwatchRedOutline,
  Ticket as TicketOutline,
  WifiBox as WifiBoxOutline,
  WifiBoxWhite as WifiBoxWhiteOutline,
  Wrench as WrenchOutline,
} from '~/components/Icons/outline';
import {
  Bolt as BoltFill,
  Check as CheckFill,
  Clock as ClockFill,
  DollarBill as DollarBillFill,
  Gift as GiftFill,
  Headset as HeadsetFill,
  Indicator as Indicator,
  Lock as LockFill,
  Phone as PhoneFill,
  Play as PlayFill,
  PlayCircle as PlayCircleFill,
  Speedometer as SpeedometerFill,
  Star as StarFill,
  Unlock as UnlockFill,
  Users as UsersFill,
  Video as VideoFill,
  WholeHomeBundlePremium as WholeHomeBundlePremiumFill,
  Wifi as WifiFill,
} from '~/components/Icons/fill';

export const iconMap = {
  'check-circle-outline': CheckCircle,
  'compare-bill': CompareBill,
  'dollar-bill': DollarBillFill,
  'magnifying-glass': MagnifyingGlass,
  'play-circle': PlayCircleFill,
  'play-icon': PlayFill,
  GiftCard: GiftCard,
  arrows: ArrowsOutline,
  ban: BanOutline,
  bill: BillOutline,
  bolt: BoltFill,
  check: CheckFill,
  checkCircle: CheckCircleOutline,
  circleup: CircleUpOutline,
  coin: CoinOutline,
  confirmationCheck: ConfirmationCheckOutline,
  clock: ClockFill,
  diagonalright: DiagonalRightOutline,
  download: DownloadOutline,
  error: ErrorOutline,
  frontierLogo: FrontierLogo,
  gamepadOut: GamepadOutline,
  gift2Outline: Gift2Outline,
  giftFill: GiftFill,
  giftOutline: GiftOutline,
  globe: GlobeOutline,
  headset: HeadsetFill,
  indicator: Indicator,
  infoCircle: InfoCircleOutline,
  laptopopen: LaptopOpenOutline,
  lock: LockFill,
  Money: MoneyOutline,
  monitor: MonitorOutline,
  multipleusers: MultipleUsersOutline,
  phone: PhoneFill,
  phoneOutline: PhoneOutline,
  shieldIdentity: ShieldIdentityOutline,
  speedometer: SpeedometerFill,
  starFill: StarFill,
  starOutline: StarOutline,
  stopwatch: StopwatchOutline,
  stopwatchRed: StopwatchRedOutline,
  ticket: TicketOutline,
  unlock: UnlockFill,
  users: UsersFill,
  video: VideoFill,
  wholeHomeBundlePremium: WholeHomeBundlePremiumFill,
  wifi: WifiFill,
  wifiBox: WifiBoxOutline,
  wifiBoxWhite: WifiBoxWhiteOutline,
  wrench: WrenchOutline,
};

export const notificationsIconMap = {
  error: ErrorOutline,
  success: CheckCircleOutline,
  warning: InfoCircleOutline,
};
