import PhoneNumber from '~/components/PhoneNumber';
import { PhoneNumberHeaderStyled } from './PhoneNumberStyled';
import React, { useContext, useRef } from 'react';
import { FuelContext } from '~/providers/FuelProvider';
import { trackEvent } from '~/helpers/tracking';
import { useSelector } from 'react-redux';
import { ReduxState } from '~/types/redux';
import useIsInViewport from '~/hooks/useInViewport';

const  PhoneNumberHeader = (): JSX.Element=> {
  const { callInNumber, monarch } = useSelector((store: ReduxState) => store);
  const { router } = useContext(FuelContext);
  const { pathname } = router;
  const phoneNumberHeaderRef = useRef();
  const inViewport = useIsInViewport(phoneNumberHeaderRef);
  const currentPage = pathname === '/' ? 'address-check' : router.route.slice(1);
  const { cartShowDuringOfficeHours } = monarch;

  const hidePhone =
    !cartShowDuringOfficeHours &&
    (monarch?.cartHideOnServiceability ||
      monarch?.cartHideOnProductSelection ||
      monarch?.cartHideOnContactCredit ||
      monarch?.cartHideAfterContactCredit);

  if (hidePhone) {
    return;
  }

  const handleCallButton = () => {
    trackEvent({
      action: 'elementClicked',
      data: {
        elementType: 'BUTTON',
        location: currentPage,
        position: 'header',
        text: 'call button',
      },
    });
  };

  if (inViewport) {
    trackEvent({
      action: 'elementViewed',
      data: {
        elementType: 'BUTTON',
        location: currentPage,
        position: 'header',
        text: 'call button',
      },
    });
  }

  return (
    <PhoneNumberHeaderStyled>
      <p>Need help?</p>
      <PhoneNumber number={callInNumber} aria-labelledby="Contact Frontier" onClick={handleCallButton} />
    </PhoneNumberHeaderStyled>
  );
}

PhoneNumberHeader.displayName = 'Header'
export default PhoneNumberHeader;


