import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${(props) => {
    const { theme } = props;
    const { colors, media, fonts } = theme;

    return css`
      html,
      body,
      div,
      span,
      applet,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      a,
      abbr,
      acronym,
      address,
      big,
      cite,
      code,
      del,
      dfn,
      em,
      img,
      ins,
      kbd,
      q,
      s,
      samp,
      small,
      strike,
      strong,
      sub,
      sup,
      tt,
      var,
      b,
      u,
      i,
      center,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td,
      article,
      aside,
      canvas,
      details,
      embed,
      figure,
      figcaption,
      footer,
      header,
      hgroup,
      menu,
      nav,
      output,
      ruby,
      section,
      summary,
      time,
      mark,
      audio,
      video {
        border: 0;
        font: inherit;
        font-size: 100%;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
      }

      html {
        box-sizing: border-box;
        -ms-overflow-style: scrollbar;
      }

      body:has([data-disable-document-scroll='true']) {
        overflow: hidden;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      body {
        background-color: ${colors.primary.white};
        color: ${colors.primary.darkBlue};
        line-height: 1;
        margin: 0 !important;
        overflow-x: hidden;
        padding: 0;
      }

      body:not([data-page='plan-package']):not([data-page='address-check']):not([data-page='liw']) {
        &.layout--single-page-exp {
          background-color: ${colors.primary.whiteSmoke};
        }
      }

      body,
      input {
        color: ${colors.primary.lightBlack};
        font-family: ${fonts.objectSans};
        font-weight: 400;
        font-size: 16px;
      }

      article,
      aside,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      menu,
      nav,
      section,
      main {
        display: block;
      }

      ol,
      ul {
        list-style: none;
      }

      blockquote,
      q {
        quotes: none;
      }

      blockquote::before,
      blockquote::after,
      q::before,
      q::after {
        content: '';
        content: none;
      }

      strong,
      b {
        font-weight: 700;
      }

      em,
      i {
        font-style: italic;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      img {
        display: block;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      button,
      input,
      optgroup,
      select,
      textarea {
        font-family: inherit;
      }

      iframe {
        border: 0;
      }

      sup {
        font-size: 10px;
        line-height: 15px;
        vertical-align: super;
      }

      /* Help anchor footer to bottom of page */
      #__next {
        display: flex;
        flex-direction: column;
        height: 100vh;
      }

      .no-scroll {
        overflow: hidden;
      }

      /* React Tippy portal styles */
      .tippy-tooltip {
        padding: 0;
      }
      .tippy-popper {
        .tippy-tooltip.light-theme {
          background-color: transparent;
          border-radius: 0.75rem;
          border: 1px solid ${colors.secondary.aluminum};
          box-shadow: none;
          filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.15));
        }
      }

      .tippy-tooltip-content {
        background-color: ${colors.primary.white};
        border-radius: 0.75rem;
        color: ${colors.secondary.mediumGray};
        font-weight: 400;
        font-size: 0.625rem;
        line-height: 1.25;
        padding: 0.625rem 0.75rem;
        text-align: left;
        width: 100%;
      }

      .tippy-popper [x-arrow].arrow-big {
        &:after {
          border-left: 11px solid transparent;
          border-right: 11px solid transparent;
          content: '';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          transform: translate(-50%, -50%);
          width: 100%;
          z-index: -1;
        }
      }

      .tippy-popper[x-placement^='top'] [x-arrow].arrow-big {
        border-top-color: ${colors.secondary.aluminum};

        &:after {
          border-top: 11px solid ${colors.secondary.aluminum};
          top: -4px;
        }
      }

      .tippy-popper[x-placement^='bottom'] [x-arrow].arrow-big {
        border-bottom-color: ${colors.secondary.aluminum};
        right: 40% !important;

        &:after {
          border-bottom: 11px solid ${colors.secondary.aluminum};
          top: 4px;
        }
      }

      .tooltip-bold {
        font-weight: 900;
      }

      .grecaptcha-badge {
        display: none !important;
      }

      body {
        &[data-page='address-check'] {
          .grecaptcha-badge {
            display: block !important;
          }
        }
      }

      body {
        &.plan-package {
          ${media.tablet.down} {
            main.container {
              max-width: 100%;
              padding-left: 0;
              padding-right: 0;
            }
            header.page-header {
              padding-left: 1.25rem;
              padding-right: 1.25rem;
            }
          }
        }
      }

      /* Remove all animations and transitions for users who prefer. Better a11y */
      @media (prefers-reduced-motion: reduce) {
        * {
          animation-duration: 0.01ms !important;
          animation-iteration-count: 1 !important;
          transition-duration: 0.01ms !important;
          scroll-behavior: auto !important;
        }
      }

      .sr-only {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        -webkit-clip-path: inset(50%) !important;
        clip-path: inset(50%) !important;
        height: 1px !important;
        overflow: hidden !important;
        margin: -1px !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
        white-space: nowrap !important;
      }
    `;
  }}
`;

export default GlobalStyle;
