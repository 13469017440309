import { Product, OfferBannerObject } from '~/types/products';
import { calculateDayDifference } from '~/helpers/calculateDayDifference';

export const getTopOffer = (
  offers: OfferBannerObject[],
  product: Product,
  addressOfferType: string
): OfferBannerObject | null => {
  const applicableOffers = offers.filter((offer) => {
    // Filter out offers for other products
    const productMatch = offer.productName === product.name;
    if (!productMatch) return false;

    // Filter out offers that do not apply to the users current market
    if (offer.market === 'National' && addressOfferType !== 'default') return false;
    if (offer.market !== 'National' && offer.market !== 'All' && addressOfferType !== offer.market) return false;

    // Filter out expired offers
    if (!offer.expirationDate) return true;
    const daysRemaining = calculateDayDifference(offer.expirationDate, new Date().toDateString());
    return daysRemaining >= 0;
  });

  // Return null if no applicable offers
  if (!applicableOffers || applicableOffers.length === 0) return null;

  // Return the top ranked offer
  return applicableOffers.sort((a, b) => a.offerRank - b.offerRank)[0];
};
