import { UtmParams } from '~/types/common';
import { getCookie } from './getCookie';

export const getUtmParamsFromCookie = (): UtmParams | null => {
  try {
    const queryString = getCookie('queryString');
    if (!queryString) {
      return null;
    }

    const searchParams = new URLSearchParams(queryString);

    // Define the UTM parameters we want to extract
    const utmParams: (keyof UtmParams)[] = ['utm_term', 'utm_content', 'utm_plan', 'utm_pp'];

    // Create an object to store the UTM parameters
    const params = utmParams.reduce<UtmParams>((acc, param) => {
      const value = searchParams.get(param);
      if (value) acc[param] = value;
      return acc;
    }, {});

    return Object.keys(params).length > 0 ? params : null;
  } catch (error) {
    console.error('Error parsing UTM parameters:', error);
    return null;
  }
};
