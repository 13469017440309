import React, { SVGProps } from 'react';

const Security = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-bundle-premium"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.125 6C10.4681 6 9.125 7.34315 9.125 9V10C9.125 11.6569 10.4681 13 12.125 13H13.125C14.7819 13 16.125 11.6569 16.125 10V9C16.125 7.34315 14.7819 6 13.125 6H12.125ZM12.125 8C11.5727 8 11.125 8.44772 11.125 9V10C11.125 10.5523 11.5727 11 12.125 11H13.125C13.6773 11 14.125 10.5523 14.125 10V9C14.125 8.44771 13.6773 8 13.125 8H12.125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.625 3L20.5931 3.20319C18.6659 3.39591 16.728 2.96178 15.0671 1.96529L12.625 0.5L10.1829 1.96529C8.52204 2.96178 6.58407 3.39591 4.65686 3.20319L2.625 3V11.3381C2.625 14.8507 4.46799 18.1058 7.48004 19.913L12.625 23L17.77 19.913C20.782 18.1058 22.625 14.8507 22.625 11.3381V3ZM20.625 5.20869C18.3248 5.40333 16.02 4.8694 14.0382 3.68027L12.625 2.83238L11.2118 3.68027C9.22997 4.8694 6.92519 5.40333 4.625 5.20869V11.3381C4.625 13.4777 5.47977 15.4979 6.95133 16.9783L7.01103 16.9182C8.23117 15.6904 9.89065 15 11.6216 15H13.4365C15.2461 15 16.9711 15.7542 18.1992 17.0766C19.7317 15.588 20.625 13.5255 20.625 11.3381V5.20869ZM16.5893 18.2891C15.751 17.4659 14.6199 17 13.4365 17H11.6216C10.4759 17 9.37535 17.4369 8.54266 18.2182L12.625 20.6676L16.5893 18.2891Z"
      />
    </svg>
  );
};

Security.displayName = 'Security';

export default Security;
