import { ActionTypes, Actions } from '~/types/address';
import { ReduxState } from '~/types/redux';
import assign from 'lodash/assign';
import merge from 'lodash/merge';

const address = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_ADDRESS:
      return assign({}, state, {
        address: {
          [action.key]: action.address,
        },
      });
    case ActionTypes.UPDATE_ADDRESS:
      return merge({}, state, {
        address: {
          [action.key]: action.address,
        },
      });
    case ActionTypes.SET_ELIGIBILITY:
      return merge({}, state, {
        eligibility: action.eligibility,
      });
    case ActionTypes.SET_PROMOTIONS:
      return assign({}, state, {
        products: {
          promotions: action.promotions,
        },
      });
    case ActionTypes.RESET_ADDRESS_ROUTE:
      return assign({}, state, {
        eligibility: undefined,
        address: undefined,
        legal: undefined,
        products: undefined,
        unservRedirect: undefined,
        enrichedCustomer: undefined,
        discoveryQuestions: undefined,
        recommendation: undefined,
        quiz: { quizStarted: false, currentQuestionIndex: 0, answers: {} },
      });
    case ActionTypes.ADDRESS_HISTORY:
      return assign({}, state, {
        addressHistory: [...state.addressHistory, action.addressKey],
      });
    case ActionTypes.SET_UNSERV_REASON:
      return assign({}, state, {
        unservReason: action.unservReason,
      });
    case ActionTypes.SET_UNSERV_REDIRECT:
      return assign({}, state, {
        unservRedirect: action.unservRedirect,
      });
    case ActionTypes.SET_VOICE_ELIGIBLE:
      return assign({}, state, {
        address: {
          ...state.address,
          voiceEligible: action.voiceEligible,
        },
      });
    default:
      return state;
  }
};

export default address;
