// Page routes need to be in order of the cart flow.
export const PAGE_ROUTES = {
  ADDRESS_CHECK: '/',
  UNSERVICEABLE_SOFT: '/unserviceable-soft',
  UNSERVICEABLE_HARD: '/unserviceable',
  UNSERVICEABLE_ALLCONNECT: '/unserviceable-allconnect',
  UNSERVICEABLE_ZIPLY: '/unserviceable-ziply',
  LIW: '/liw',
  EXISTING_CUSTOMER: '/existing-customer',
  PLAN_PACKAGE: '/plan-package',
  CONTACT_CREDIT: '/contact-credit',
  BILLING_SUMMARY: '/order',
  CONFIRMATION: '/confirmation',
  GENERIC_ERROR: '/error',
  POSID_ERROR: '/posid',
  BUY_FIBER: '/fiber',
  PLAN_FIBER: '/plan-fiber',
  ORDER_FIBER: '/order-fiber',
  CONFIRMATION_FIBER: '/confirmation-fiber',
  RESUME: '/resume',
  PLAN_RESUME: '/plan-resume',
  CONTACT_RESUME: '/contact-resume',
  ORDER_RESUME: '/order-resume',
  CONFIRMATION_RESUME: '/confirmation-fiber',
  MY_CUSTOM_QUOTE: '/my-custom-quote',
  CUSTOMIZE: '/customize',
  BUILD: '/build',
  RECOMMENDATION: '/customize/recommendation',
  MY_CUSTOM_QUOTE_PLAN_PACKAGE: '/my-custom-quote/plan-package',
  CHECKOUT: '/checkout',
};

export const PAGE_ROUTE_VALUES = Object.values(PAGE_ROUTES);
