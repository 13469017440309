import { MonarchEvaluationsRuleSet, formatMonarchEvaluations, formatPreampExperience, MonarchPreampExperience } from '@redventures/cohesion-utils-react';
import clientSide from '~/helpers/clientSide';

const checkUnservRedirectEnabled = (currentPage: string): boolean => {
  let unservRedirectEnabled = false;
  if (clientSide) {
    window?.cohesion('monarch:done', function (err, evaluations) {
      const rules: MonarchEvaluationsRuleSet = formatMonarchEvaluations(evaluations);
      if (rules?.trafficFlow?.value) {
        const experience = formatPreampExperience(rules.trafficFlow.value as unknown as MonarchPreampExperience | null);
        if (experience) {
          const placement =
            experience.placements.find(({ placementName }) => placementName === 'editable-loader-config') || {};
          if ('metadata' in placement) {
            unservRedirectEnabled = placement?.metadata[currentPage]?.unservRedirectEnabled === 'true';
          }
        }
      }
    });
  }
  return unservRedirectEnabled;
};

export default checkUnservRedirectEnabled;
