import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SmallCircleLoader = styled.div.attrs({
  className: 'small-loader',
})`
  ${() => {
    return css`
      height: 24px;
      width: 24px;
      animation: ${rotate} 0.8s infinite linear;
      border: 3px solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      margin-left: 15px;
    `;
  }};
`;

SmallCircleLoader.displayName = 'SmallCircleLoader';

export default SmallCircleLoader;
