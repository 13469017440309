import { Dispatch } from 'redux';
import { setSessionExpired } from '~/redux/actions/common';

const SALES_JOURNEY_ERROR_CODES = [
  'FRAPI-300',
  'FRAPI-301',
  'FRAPI-302',
  'FRAPI-303'
];

const SESSION_EXPIRATION_ERROR_CODES = [
  'FRAPI-205',
  'FRAPI-501'
];

const isSessionExpirationError = (frapiCode) => {
  if (!frapiCode) return false;

  // Sales Journey Errors
  if (SALES_JOURNEY_ERROR_CODES.includes(frapiCode)) return true;

  // Session Expired Errors
  if (SESSION_EXPIRATION_ERROR_CODES.includes(frapiCode)) return true;

  return false;
};

interface HandleExpiredSession {
  dispatch?: Dispatch;
  frapiErrorCode?: string;
}

export const handleExpiredSession = ({ dispatch, frapiErrorCode }: HandleExpiredSession) => {
  if (dispatch) {
    const isSessionExpired = isSessionExpirationError(frapiErrorCode);
    if (isSessionExpired) {
      dispatch(setSessionExpired(true));
    }
  }
};

export default isSessionExpirationError;
