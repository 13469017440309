import { SyntheticEvent } from 'react';
import * as tracker from './trackers';

export const log = (data: Record<string, unknown>, event: SyntheticEvent, action: string): void => {
  const styles = 'font-weight: bold; color: #ff0000;';

  if (Object.keys(data).length) {
    console.groupCollapsed(`%cEvent: ${action}`, styles);
    console.table(data);
    console.groupEnd();
  } else {
    console.log(`%cEvent: ${action}`, styles);
  }
};

export const production = {
  appointmentSelected: [tracker.Google.appointmentSelected],
  addedExtra: [tracker.Adobe.addedExtra],
  addedSecurity: [tracker.Adobe.addedSecurity],
  addressSuggestion: [tracker.Adobe.addressSuggestion],
  acceptTerms: [tracker.Adobe.acceptTerms],
  addedToCart: [tracker.Adobe.addedToCart],
  callToOrder: [tracker.Adobe.callToOrder],
  cartEntry: [
    tracker.Amz.cartEntry,
    tracker.Adobe.cartEntry,
    tracker.Expo.cartEntry,
    tracker.Dstillery.cartEntry,
    tracker.Jivox.cartEntry,
    tracker.TradeDesk.cartEntry,
  ],
  clientErrored: [tracker.Tagular.clientErrored],
  completeOrder: [tracker.Adobe.completeOrder, tracker.Tagular.elementClicked],
  creditCheckRequested: [tracker.Amz.creditCheckRequested, tracker.Tagular.formSubmittedV3],
  creditCheckResponded: [tracker.Adobe.creditCheckResponded, tracker.Google.creditCheckResponded],
  creditCheckSuccess: [tracker.Google.creditCheckSuccess],
  creditCheckFailed: [tracker.Google.creditCheckFailed],
  editPlan: [tracker.Google.editPlan, tracker.Tagular.editPlan],
  elementClicked: [tracker.Tagular.elementClicked],
  elementViewed: [log, tracker.Tagular.elementViewed],
  fieldInputted: [tracker.Tagular.fieldInputted],
  movingToServiced: [tracker.Adobe.movingToServiced],
  navigateToPrevious: [tracker.Adobe.navigateToPrevious],
  notServiceable: [tracker.Amz.notServiceable, tracker.Dstillery.notServiceable],
  orderConfirmation: [
    tracker.Adobe.orderConfirmation,
    tracker.Amz.orderConfirmation,
    tracker.Jivox.orderConfirmation,
    tracker.Dstillery.orderConfirmation,
  ],
  orderConfirmationVAS: [tracker.Adobe.orderConfirmationVAS],
  orderSessionStarted: [tracker.Tagular.OrderSessionStarted],
  packageSelected: [
    tracker.Jivox.packageSelected,
    tracker.Amz.packageSelected,
    tracker.Google.packageSelected,
    tracker.Dstillery.packageSelected,
  ],
  selectedInstallAppointment: [tracker.Adobe.selectedInstallAppointment],
  pageView: [tracker.Tagular.pageViewed, tracker.Adobe.pageView, tracker.Google.pageView, tracker.Bing.pageView],
  personalDetailsEntered: [
    tracker.Amz.personalDetailsEntered,
    tracker.Google.personalDetailsEntered,
    tracker.Tagular.elementClicked,
    tracker.Tagular.formSubmittedV3,
  ],
  productClicked: [tracker.Tagular.productClicked],
  productViewed: [log, tracker.Tagular.productViewed],
  removeProducts: [tracker.Adobe.removeProducts],
  seviceabilityConfirmed: [
    tracker.Jivox.serviceabilityRan,
    tracker.Amz.seviceabilityConfirmed,
    tracker.Dstillery.seviceabilityConfirmed,
  ],
  trackError: [tracker.Adobe.trackError],
  trackFiloHelp: [tracker.Adobe.trackFiloHelp],
  trackFiloHelpCTA: [tracker.Google.trackFiloHelp],
  displayModal: [tracker.Adobe.displayModal],
  editAddress: [tracker.Adobe.editAddress],
  depositRequired: [tracker.Adobe.depositRequired],
  outstandingBalance: [tracker.Adobe.outstandingBalance],
  depositOutstandingBalance: [tracker.Adobe.depositOutstandingBalance],
  toolTip: [tracker.Adobe.toolTip],
  daysUntilFirstAvailable: [tracker.Adobe.daysUntilFirstAvailable],
  toggleBundles: [tracker.Adobe.toggleBundles],
};

export const development = {
  appointmentSelected: [log],
  addedExtra: [log, tracker.Adobe.addedExtra],
  addedSecurity: [log, tracker.Adobe.addedSecurity],
  addressSuggestion: [log, tracker.Adobe.addressSuggestion],
  acceptTerms: [log, tracker.Adobe.acceptTerms],
  addedToCart: [log, tracker.Adobe.addedToCart],
  callToOrder: [log, tracker.Adobe.callToOrder],
  cartEntry: [log, tracker.Adobe.cartEntry, tracker.TradeDesk.cartEntry],
  completeOrder: [log, tracker.Adobe.completeOrder, tracker.Tagular.elementClicked],
  clientErrored: [log, tracker.Tagular.clientErrored],
  creditCheckRequested: [log, tracker.Tagular.formSubmittedV3],
  creditCheckResponded: [log, tracker.Adobe.creditCheckResponded],
  creditCheckSuccess: [log],
  creditCheckFailed: [log],
  elementClicked: [log, tracker.Tagular.elementClicked],
  elementViewed: [log, tracker.Tagular.elementViewed],
  editPlan: [log, tracker.Tagular.editPlan],
  fieldInputted: [log, tracker.Tagular.fieldInputted],
  movingToServiced: [log, tracker.Adobe.movingToServiced],
  navigateToPrevious: [log, tracker.Adobe.navigateToPrevious],
  notServiceable: [log],
  orderConfirmation: [log, tracker.Adobe.orderConfirmation],
  orderConfirmationVAS: [tracker.Adobe.orderConfirmationVAS],
  orderSessionStarted: [log, tracker.Tagular.OrderSessionStarted],
  packageSelected: [log],
  selectedInstallAppointment: [tracker.Adobe.selectedInstallAppointment],
  pageView: [log, tracker.Tagular.pageViewed, tracker.Adobe.pageView],
  personalDetailsEntered: [log, tracker.Tagular.elementClicked, tracker.Tagular.formSubmittedV3],
  productClicked: [log, tracker.Tagular.productClicked],
  productViewed: [log, tracker.Tagular.productViewed],
  removeProducts: [log, tracker.Adobe.removeProducts],
  seviceabilityConfirmed: [log],
  trackError: [log, tracker.Adobe.trackError],
  trackFiloHelp: [log, tracker.Adobe.trackFiloHelp],
  trackFiloHelpCTA: [log],
  displayModal: [log, tracker.Adobe.displayModal],
  editAddress: [log, tracker.Adobe.editAddress],
  depositRequired: [log, tracker.Adobe.depositRequired],
  outstandingBalance: [log, tracker.Adobe.outstandingBalance],
  depositOutstandingBalance: [log, tracker.Adobe.depositOutstandingBalance],
  toolTip: [log, tracker.Adobe.toolTip],
  daysUntilFirstAvailable: [log, tracker.Adobe.daysUntilFirstAvailable],
  apiRetry: [log],
  toggleBundles: [tracker.Adobe.toggleBundles],
};
