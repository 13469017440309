import { Address } from './address';
import { LegalMap } from './legal';
import { BaseResponse, InstallationOptions } from './common';
import { BillingSummary, Contact } from './contact-credit';
import { Disclosure } from './order';
import { OfferObject } from './offerObject';

export interface GetProductsResponse extends BaseResponse {
  quoteId: string;
  quoteNumber: string;
  accountUuid: string;
  availableProducts: Product[];
  broadbandType: string;
  addressHasExistingService: boolean;
  legal: LegalMap;
  customer: Omit<Contact, 'firstname' | 'lastname'> & { firstName: string; lastName: string };
  gmtOffset?: number;
  serviceAddress: Address;
  unservReason?: string;
  firstAvailable: string;
  productList?: OfferObject[];
}

export interface ResumeQuoteResponse {
  success: boolean;
  quoteId: string;
  quoteNumber: string;
  serviceAddress: Address;
  billingSummary: BillingSummary;
  creditChecked?: string;
  cartPage: string;
  accountUuid: string;
  sweetenerSelected?: boolean;
  selectedInternetProductId?: string;
  selectedVoiceProductId?: string;
  selectedVASProductIds?: Array<string>;
  selectedSecureProductIds?: Array<string>;
  selectedVideoProductIds?: Array<string>;
  installationOptions?: InstallationOptions;
  contactVerifyRequired?: boolean;
}

export interface SelectProductsResponse {
  success: boolean;
  billingSummary: BillingSummary;
  installationOptions?: InstallationOptions;
  contactVerifyRequired: boolean;
  contactVerifyComplete?: boolean;
}

export interface PhoneVerificationResponse {
  success: boolean;
  message: string;
}

export interface PhoneVerificationRequest {
  code: string;
}

export interface PhoneVerificationResponse {
  success: boolean;
  message: string;
}

export interface PhoneVerificationRequest {
  code: string;
}

export interface CreditBypassResponse {
  success: boolean;
  billingSummary: BillingSummary;
  disclosures: Disclosure[];
}

export interface ContactGetResponse {
  success: boolean;
  accountUuid: string;
  hasAutoPay: string;
  hasPaperlessBill: string;
}

export interface SelectedProductRequest {
  selectedProducts: SelectedProduct[];
  isLandingPage?: boolean;
}

export interface SelectedProduct {
  price?: Price;
  additionalOptions?: AdditionalOptions;
  productId: string;
  name?: string;
  type: string;
  dataspeed?: {
    downloadSpeedMbps: number;
    uploadSpeedMbps: number;
  };
  configurations?: SelectedProductConfiguration[];
}

export interface SelectedProductConfiguration {
  productId?: string;
  productKey: string;
  name?: string;
  active: boolean;
}

export interface CarouselProps {
  productIndex: number;
  paginate: (index: number) => void;
  direction: number;
}

export type Option = {
  value: string;
  label: string;
};

export type Feature = {
  icon: string;
  text?: string;
  bold?: boolean;
  tooltip?: string;
};

export type SweetenerBanner = {
  text: string;
  legal: string;
  bold: boolean;
};

interface AmountWithSweetenerType {
  [key: string]: number;
}

export type Price = {
  amount: number;
  amountMajor?: number;
  amountMinor?: number;
  amountNoAutopay?: number;
  originalAmount?: number;
  amountACH?: number;
  amountDebit?: number;
  amountCredit?: number;
  amountWithSweetener?: AmountWithSweetenerType;
};

export type Sweetener = {
  name: string;
  amount: number;
  legal: string;
  key?: string;
  productId?: string;
};

export type OfferBannerObject = {
  productName: string;
  offerType: string;
  offerText: string;
  market: string;
  offerPlacement: string;
  offerRank: number;
  expirationDate?: string;
  legal?: string;
};

interface DetailsBannerItem {
  name: string;
  text: string;
}

export type Dataspeed = {
  uploadInKbps: number;
  downloadInKbps: number;
  uploadInMbps: number;
  downloadInMbps: number;
};

type ConfigurationsOptions = {
  active: boolean;
  id: string;
  name: string;
  displayName: string;
  price: string;
  amount?: number;
};

type Configurations = {
  id: string;
  name: string;
  options: ConfigurationsOptions[];
  active: boolean;
  productKey: string;
};

type AdditionalOptions = {
  productId: string;
  name: string;
  displayName: string;
  description: string | Record<string | number, string>;
  maxAmount?: Record<string | number, number>;
  type: string;
  displayType: string;
  price: Price;
  productKey: string;
  selected?: boolean;
};

export type Product = {
  productId: string;
  name: string;
  description: string | Record<string | number, string>;
  descriptionTooltip?: string | null;
  maxAmount?: Record<string | number, number>;
  dataspeed: Dataspeed;
  type?: string;
  serviceType?: string;
  price: Price;
  features: Feature[];
  displayType: string;
  sweeteners: {
    [key: string]: Sweetener;
  };
  topOffer?: OfferBannerObject;
  is200?: boolean;
  is500?: boolean;
  is1Gig?: boolean;
  is2Gig?: boolean;
  is5Gig?: boolean;
  is7Gig?: boolean;
  shortLegal: string;
  shortLegalIndiana?: string;
  shortLegalNetflix?: string;
  detailsBanner: DetailsBannerItem[];
  configurations?: Configurations[];
  productConfigurations?: Configurations[];
  additionalOptions?: AdditionalOptions[];
  disclaimer?: string;
  tooltip?: string;
  productKey?: string;
  promo?: string;
  displayStrikethrough?: boolean;
  displayName?: string;
  labelId?: string;
  isVrcSelected?: boolean;
  category?: string;
  sticker?: {
    enabled?: boolean;
    text?: string;
  };
};

export type SelectedProductsState = {
  internet: Product | undefined;
  voice: Product | undefined;
  secure?: {
    [key: string]: Product | SelectedProduct;
  };
  modal?: Product;
  vas?: {
    [key: string]: Product;
  };
  video?:
    | {
        [key: string]: Product;
      }
    | boolean;
};

export type SelectedResumeProductsState = {
  internet: Product | undefined;
  voice: Product | undefined;
  secure?: {
    [key: string]: Product;
  };
  modal?: Product;
  vas?: {
    [key: string]: Product;
  };
  video?: {
    [key: string]: Product;
  };
};

export enum ModalKeys {
  AGREEMENTS = 'agreements',
  EERO = 'eero',
  VAS = 'vas',
  COMMERCIAL_CC = 'commercial_cc',
  RESUME = 'resume',
  YTV = 'ytv',
  FROZEN_CREDIT = 'frozen_credit',
  LIFT_CREDIT_FREEZE = 'lift_credit_freeze',
  LIFT_CREDIT_FREEZE_v2 = 'lift_credit_freeze_v2',
  OTP_VERIFY = 'otp_verify',
  EDIT_ADDRESS = 'edit_address',
  EDIT_PLAN = 'edit_plan',
  BACK_BALANCE = 'back_balance',
  AUTOPAY_ERROR = 'autopay_error',
  AUTOPAY_DISCOUNT = 'autopay_discount',
  BROADBAND_CONSUMER_LABEL = 'broadband_consumer_label',
  VRC_OFFER = 'vrc_offer',
  COMPARE_PLANS = 'compare_plans',
  NFLST = 'nflst',
  WIFI_SECURE = 'wifi_secure',
  ADD_ON_COMPATIBILITY = 'add_on_compatibility',
  UPSELL = 'upsell',
  CARD_CONSOLIDATION = 'card_consolidation',
}

export type Modals =
  | ModalKeys.AGREEMENTS
  | ModalKeys.EERO
  | ModalKeys.VAS
  | ModalKeys.COMMERCIAL_CC
  | ModalKeys.RESUME
  | ModalKeys.YTV
  | ModalKeys.FROZEN_CREDIT
  | ModalKeys.LIFT_CREDIT_FREEZE
  | ModalKeys.LIFT_CREDIT_FREEZE_v2
  | ModalKeys.OTP_VERIFY
  | ModalKeys.EDIT_ADDRESS
  | ModalKeys.EDIT_PLAN
  | ModalKeys.BACK_BALANCE
  | ModalKeys.AUTOPAY_ERROR
  | ModalKeys.AUTOPAY_DISCOUNT
  | ModalKeys.BROADBAND_CONSUMER_LABEL
  | ModalKeys.VRC_OFFER
  | ModalKeys.COMPARE_PLANS
  | ModalKeys.NFLST
  | ModalKeys.WIFI_SECURE
  | ModalKeys.ADD_ON_COMPATIBILITY
  | ModalKeys.UPSELL
  | ModalKeys.CARD_CONSOLIDATION
  | undefined;

// Redux
export enum ActionTypes {
  SELECT_PRODUCT = 'product/select',
  CLEAR_SELECT_PRODUCT = 'product/select/clear',
  SET_PRODUCTS = 'products/set',
  SET_OFFER_TYPE = 'products/offerType/set',
  SET_BROADBAND_TYPE = 'products/broadbandType/set',
  SET_SELECTED_SWEETENER = 'products/planPageSelections/selectedSweetener/set',
  SET_NETFLIX_BUNDLE = 'products/planPageSelections/selectedNetflix/set',
  SET_PHONE_SELECTION = 'products/planPageSelections/phoneSelection/set',
  SET_FIRST_AVAILABLE = 'products/firstAvailable/set',
  SET_NDI_BANNER = 'products/ndiBanner/set',
  SET_LANDING_PAGE_PRODUCT = 'products/landingPageProduct/set',
  SET_LANDING_PAGE_PRODUCT_AVAILABLE = 'products/landingPageProductAvailable/set',
  SET_OFFER_OBJECTS = 'products/offerObjects/set',
  SET_OFFER_MODAL_SELECTED = 'products/offerModalSelected/set',
  SET_INCLUDED_WITH_WHOLE_HOME_WIFI_PLUS = 'products/includedWithWholeHomeWifiPlus/set',
  SET_CARD_CONSOLIDATION = 'products/cardConsolidation/set',
  SET_CARD_CONSOLIDATION_MODAL = 'products/cardConsolidation/modal/set',
}

export enum SweetenerKeys {
  EERO = 'eero',
  VRC = 'vrc',
  WAIVE_INSTALL = 'waive-install-charge',
  WIFI_SECURITY_FREE_TRIAL = 'wifi-security-free-trial',
  WHOLE_HOME_WIFI_FREE_TRIAL = 'whole-home-wifi-free-trial',
}

export interface SetProductsAction {
  type: ActionTypes.SET_PRODUCTS;
  available: {
    [key: string]: Product[];
  };
  offerLevelSweeteners: {
    has2Gig: boolean;
    sweetenerLegalKeys: SweetenerKeys[];
  };
  offerObjects?: OfferObject[];
}

export interface SetOfferTypeAction {
  type: ActionTypes.SET_OFFER_TYPE;
  offerType: string;
}

export interface SetBroadbandTypeAction {
  type: ActionTypes.SET_BROADBAND_TYPE;
  broadbandType: string;
}

export interface SetOfferObjectsAction {
  type: ActionTypes.SET_OFFER_OBJECTS;
  offerObjects: OfferObject[];
}

export interface SelectProductAction {
  type: ActionTypes.SELECT_PRODUCT;
  selectedState: SelectedProductsState | SelectedResumeProductsState;
}

export interface ClearSelectProductAction {
  type: ActionTypes.CLEAR_SELECT_PRODUCT;
}

export interface SetSelectedSweetenerAction {
  type: ActionTypes.SET_SELECTED_SWEETENER;
  selectedSweetener: Option;
}

export interface SetSelectedNetflixAction {
  type: ActionTypes.SET_NETFLIX_BUNDLE;
  selectedNetflix: boolean;
}

export interface SetPhoneSelection {
  type: ActionTypes.SET_PHONE_SELECTION;
  phoneSelection: string;
}

export interface FirstAvailableAction {
  type: ActionTypes.SET_FIRST_AVAILABLE;
  firstAvailable: string;
}

export interface SetNDIBannerAction {
  type: ActionTypes.SET_NDI_BANNER;
  ndiBanner: boolean;
}

export interface SetLandingPageProduct {
  type: ActionTypes.SET_LANDING_PAGE_PRODUCT;
  landingPageProduct: string;
}

export interface SetLandingPageProductAvailable {
  type: ActionTypes.SET_LANDING_PAGE_PRODUCT_AVAILABLE;
  landingPageProductAvailable: boolean;
}

export interface SetOfferModalSelectedAction {
  type: ActionTypes.SET_OFFER_MODAL_SELECTED;
  product: Product;
}

export interface SetIncludedWhiWholeHomeWifiPlus {
  type: ActionTypes.SET_INCLUDED_WITH_WHOLE_HOME_WIFI_PLUS;
  includedWithWholeHomeWifiPlus: boolean;
}

export interface SetCardConsolidation {
  type: ActionTypes.SET_CARD_CONSOLIDATION;
  isEnabled: boolean;
}

export interface SetCardConsolidationModal {
  type: ActionTypes.SET_CARD_CONSOLIDATION_MODAL;
  checked: boolean;
  option: string;
  selectedType: string;
}


export type Actions =
  | SetProductsAction
  | SelectProductAction
  | ClearSelectProductAction
  | SetOfferTypeAction
  | SetBroadbandTypeAction
  | SetSelectedSweetenerAction
  | SetSelectedNetflixAction
  | SetPhoneSelection
  | FirstAvailableAction
  | SetNDIBannerAction
  | SetLandingPageProduct
  | SetLandingPageProductAvailable
  | SetOfferObjectsAction
  | SetOfferModalSelectedAction
  | SetIncludedWhiWholeHomeWifiPlus
  | SetCardConsolidation
  | SetCardConsolidationModal;


export interface PlanCardsMetadata {
  [key: string]: {
    bannerEnabled: string;
    bannerDetails: {
      bannerText: string;
      bannerIcon: string;
    };
    name: string;
    order: string;
    bestFor?: string;
    featureList?: [{ text: string; legal: string }];
    useBulletsWithFeatureList: string;
    outerBanner?: {
      outerBannerText?: string;
      outerBannerBackground?: string;
    };
    innerBanner?: {
      innerBannerIcon?: string;
      innerBannerText?: string;
      innerBannerBackground?: string;
    };
    totalValueEnabled?: string;
  };
}

export interface MatchedProducts {
  [key: string]: Product;
}
