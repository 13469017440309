import { ReduxState } from '~/types/redux';
import assign from 'lodash/assign';
import { Actions, ActionTypes } from '~/types/enrich-customer';

const enrichedCustomer = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_ENRICHED_CUSTOMER:
      return assign({}, state, {
        enrichedCustomer: action.enrichedCustomer,
      });
    default:
      return state;
  }
};

export default enrichedCustomer;
