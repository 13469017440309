import { PLAN_SPEEDS_IDS } from '~/constants/products';
import { Product } from '~/types/products';

const getOTKOfferType = (availableProducts: Product[]): string => {
  const getPriceByProductId = (productId) => {
    const product = availableProducts.find(
      (product) => product.productId === productId
    );
    return product?.price?.amount;
  };

  const fiber500Price = getPriceByProductId(PLAN_SPEEDS_IDS['500']);
  const fiber1GigPrice = getPriceByProductId(PLAN_SPEEDS_IDS['1000']);
  const fiber2GigPrice = getPriceByProductId(PLAN_SPEEDS_IDS['2000']);
  const fiber5GigPrice = getPriceByProductId(PLAN_SPEEDS_IDS['5000']);
  const fiber7GigPrice = getPriceByProductId(PLAN_SPEEDS_IDS['7000']);

  if (
    fiber500Price === 39.99 &&
    fiber1GigPrice === 44.99 &&
    fiber2GigPrice === 89.99 &&
    fiber5GigPrice === 119.99
  ) {
    return 'laOfferiONT';
  }

  if (
    fiber1GigPrice === 49.99 &&
    fiber2GigPrice === 94.99 &&
    fiber5GigPrice === 124.99
  ) {
    return 'laOffer';
  }

  // if (
  //   fiber500Price === 29.99 &&
  //   fiber1GigPrice === 49.99 &&
  //   fiber2GigPrice === 69.99 &&
  //   fiber5GigPrice === 99.99 &&
  //   (!fiber7GigPrice || fiber7GigPrice === 199.99)
  // ) {
  //   return 'OFS_test';
  // }

  if (
    fiber500Price === 29.99 &&
    fiber1GigPrice === 54.99 &&
    fiber2GigPrice === 69.99 &&
    fiber5GigPrice === 99.99 &&
    (!fiber7GigPrice || fiber7GigPrice === 199.99)
  ) {
    return 'OFS_test2';
  }
  if (
    fiber500Price === 29.99 &&
    fiber1GigPrice === 49.99 &&
    fiber2GigPrice === 69.99 &&
    fiber5GigPrice === 94.99 &&
    (!fiber7GigPrice || fiber7GigPrice === 199.99)
  ) {
    return 'OFS_test3';
  }
  if (
    fiber500Price === 44.99 &&
    fiber1GigPrice === 64.99 &&
    fiber2GigPrice === 69.99 &&
    fiber5GigPrice === 99.99 &&
    (!fiber7GigPrice || fiber7GigPrice === 299.99)
  ) {
    return 'OFS_test4';
  }
  if (
    fiber500Price === 29.99 &&
    fiber1GigPrice === 49.99 &&
    fiber2GigPrice === 69.99 &&
    fiber5GigPrice === 99.99 &&
    (!fiber7GigPrice || fiber7GigPrice === 199.99)
  ) {
    return 'OFS';
  }
  return 'default';
};

export default getOTKOfferType;
